import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getCustSuccDashboardOverview = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(

                SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/customerSuccessEngineer/dsp/overviewData",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting dashboard data."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting dashboard data.");
        }
    });
};

export const getStoresCount = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/customerSuccessEngineer/stores/store/count`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting stores count.");
        }
        return res.stores_count;
    } catch (err) {
        throw (err)
    }
}

export const getAllOrdersCount = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/customerSuccessEngineer/rewardOrders/count`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        console.log("res",res);
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting orders count.");
        }
        return res;
    } catch (err) {
        throw (err)
    }
}