import { useEffect, useState } from 'react';
import MainContentWrapper from '../../../Components/MainContentWrapper';
import TableView from '../../../ReusableComponents/TableView/TableView';
import Popup from '../../../ReusableFunctions/Popup';
import DashBoardCard from '../../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard';
import { useNavigate } from 'react-router';
import { getUserInvoices, getUserInvoicesStats } from '../../../apis/adminAPIs/finance/userInvoices';
import { PAYMENT_STATUSES, STATUS } from '../../../appConstants';
import { PDRL_FINANCE_ROUTES } from '../../../routes';
import Search from '../../../ReusableComponents/Search';
import UserInvoicesFilter from './UserInvoicesFilter';
import { Button } from '../../../Components/Helper';
import Filter from "../../../assets/filter.svg"

const InvoicesStatCard = ({ title, statNumber, isSelected, onClick, isDisabled }) => {
    return <DashBoardCard
        isDisabled={isDisabled}
        title={title}
        data={statNumber}
        onClick={onClick}
        backGround={isSelected ? "green" : undefined}
    />
}

const InvoiceStats = ({ invoicesStats, filters, changeStatusFilter }) => {
    return <div style={{ display: "flex", columnGap: "2%" }}>
        <InvoicesStatCard
            title={`Total Invoices (₹ ${(invoicesStats.paid_invoices_amount + invoicesStats.unpaid_invoices_amount).toFixed()})`}
            statNumber={invoicesStats.total_invoices_count}
            isSelected={filters.statuses.length === 0}
            onClick={() => changeStatusFilter([])}
        />
        <InvoicesStatCard
            title={`Paid Invoice (₹ ${(invoicesStats.paid_invoices_amount).toFixed()})`}
            statNumber={invoicesStats.paid_invoices_count}
            isSelected={filters.statuses.length > 0 && filters.statuses[0] === PAYMENT_STATUSES.PAID}
            onClick={() => changeStatusFilter([PAYMENT_STATUSES.PAID])}
        />
        <InvoicesStatCard
            title={`Unpaid Invoices (₹ ${(invoicesStats.unpaid_invoices_amount).toFixed()})`}
            statNumber={invoicesStats.unpaid_invoices_count}
            isSelected={filters.statuses.length > 0 && filters.statuses[0] === STATUS.COMPLETED}
            onClick={() => changeStatusFilter([STATUS.COMPLETED])}
        />
        {/* <InvoicesStatCard
            title={"Paid Amount"}
            statNumber={(invoicesStats.paid_invoices_amount).toFixed(2)}
            isDisabled={true}
        />
        <InvoicesStatCard
            title={"Unpaid Amount"}
            statNumber={(invoicesStats.unpaid_invoices_amount).toFixed(2)}
            isDisabled={true}
        /> */}
    </div>
}

const UserInvoices = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [userInvoices, setUserInvoices] = useState([])
    const [invoicesStats, setInvoicesStats] = useState({
        total_invoices_count_for_pagination: 0,
        total_invoices_count: 0,
        paid_invoices_count: 0,
        unpaid_invoices_count: 0,
        paid_invoices_amount: 0,
        unpaid_invoices_amount: 0
    })
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 20,
        orderType: "DESC",
        statuses: [PAYMENT_STATUSES.PAID],
        searchString: undefined,
        fromDate: undefined,
        toDate: undefined,
        paymentMode: undefined
    })
    const [openFilter, setOpenFilter] = useState(false)

    const getUserInvoicesFunc = async () => {
        try {
            setIsLoading(true)
            const invoices = await getUserInvoices(filters.fromDate, filters.toDate, filters.pageSize, filters.pageNumber, filters.orderType, filters.statuses, filters.searchString, filters.paymentMode)
            const invoicesStats = await getUserInvoicesStats(filters.fromDate, filters.toDate, filters.searchString, filters.paymentMode)
            setInvoicesStats({
                total_invoices_count_for_pagination: invoices[0]?.total_invoices ? Number(invoices[0].total_invoices) : 0,
                total_invoices_count: invoicesStats.total_invoices || 0,
                paid_invoices_count: invoicesStats.paid_invoices_count || 0,
                unpaid_invoices_count: invoicesStats.unpaid_invoices_count || 0,
                paid_invoices_amount: invoicesStats.total_paid_amount || 0,
                unpaid_invoices_amount: invoicesStats.total_unpaid_amount || 0
            })
            setUserInvoices(invoices)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            Popup.alert("ERROR", "We cant load invoices right now, Please try again later.")
        }
    }

    useEffect(() => {
        getUserInvoicesFunc()
    }, [filters])

    return <>
        <MainContentWrapper header={`Customer Invoices`} rightContent={
            <div style={{ display: "flex", gap: '30px' }}>
                <Search
                    searchValue={filters.searchString}
                    onChange={(value) => {
                        setFilters({
                            ...filters,
                            pageNumber: 1,
                            searchString: value
                        })
                    }}
                />
                <Button
                    innerClassName={"filter-btn-inner"}
                    iconStyle={{ width: "18px", marginRight: "10px" }}
                    text={"Filter"}
                    icon={Filter}
                    className={"filter-btn"}
                    onClick={() => {
                        setOpenFilter(true)
                    }}
                    textClassName={"user-details-filter-text"}
                />
            </div>}
        >
            <InvoiceStats
                invoicesStats={invoicesStats}
                filters={filters}
                changeStatusFilter={(statusFiler) => setFilters({ ...filters, statuses: statusFiler, pageNumber: 1 })}
            />
            {openFilter &&
                <UserInvoicesFilter
                    filter={filters}
                    onFilterChange={(appliedFilters) => {
                        setFilters({
                            ...appliedFilters,
                            pageNumber: 1
                        })
                        setOpenFilter(false)
                    }}
                    open={openFilter}
                    onClose={() => {
                        setOpenFilter(false)
                    }}
                />
            }
            <div style={{
                margin: "20px 0",
                height: "72%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={isLoading}
                    onStateChange={async (changedState) => {
                        const { pageNumber, orderBy, orderType } = changedState;
                        setFilters({ ...filters, pageNumber, orderBy, orderType });
                    }}
                    onClickRow={(invoice) => {
                        navigate(`${PDRL_FINANCE_ROUTES.USER_INVOICE_DETAILS}`, {
                            state: {
                                invoice
                            }
                        })
                    }}
                    columns={[
                        { Header: "Invoice No.", accessor: "invoice_number" },
                        { Header: "Total Amount (₹)", accessor: "total_amount_without_bhumeet_coupon" },
                        { Header: "Paid Amount (₹)", accessor: "total_amount" },
                        { Header: "Date", accessor: "service_completed_time", isDate: true, isSortable: true, format: "dd/mm/yyyy" },
                        { Header: "Status", accessor: "status" }
                    ]}
                    count={invoicesStats.total_invoices_count_for_pagination}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={userInvoices.map((uI) => {
                        return {
                            ...uI,
                            total_amount_without_bhumeet_coupon:Number(uI.total_amount_without_bhumeet_coupon).toFixed(2),
                            total_amount: Number(uI.total_amount).toFixed(2),
                            status: uI.status === STATUS.COMPLETED ? "Unpaid" : "Paid"
                        }
                    })}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    </>
}
export default UserInvoices