import React, { useEffect, useState } from 'react';
import MainContentWrapper from '../../Components/MainContentWrapper';
import Popup from '../../ReusableFunctions/Popup';
import Loading from '../../ReusableFunctions/Loader';
import { getAllRequests, getAllRequestsCount, getRequestsGraphStats } from '../../apis/adminAPIs/requests';
import ViewRequest from './SubComponents/ViewRequest';
import AdminRequestTable from './SubComponents/AdminRequestTable';
import { getStatusWiseRequestCount } from '../../apis/adminAPIs/overview';
import StatusCountContainer from './SubComponents/StatusCountContainer';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from "react-router-dom";
import { PDRL_SUCCESS_ENGI_ROUTES } from '../../routes';
import { getOrdersStatsAPI } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/orders';
import InteractiveGraph from '../../BhuMeetTeams/BhuMeetFinance/Orders/SubComponents/OrdersGraph';
import { getRequestsCountInInterval } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/request';

const Requests = ({ dsp }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [loading, setLoading] = useState()
    const [requests, setRequests] = useState([])
    const [filters, setFilters] = useState(() => {
        if (dsp) {
            return {
                services: [],
                statuses: [],
                locations: [],
                isDspActive: undefined,
                dsps: dsp ? [dsp.dsp_id] : [],
                fromDate: undefined,
                toDate: undefined,
                searchQuery: "",
                pageSize: 15,
                pageNumber: 1
            }
        }
        const savedFilters = localStorage.getItem('requestFilters')
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters)
            return {
                ...parsedFilters,
                fromDate: parsedFilters.fromDate ? new Date(parsedFilters.fromDate) : null,
                toDate: parsedFilters.toDate ? new Date(parsedFilters.toDate) : null,
            }
        }
        return {
            services: [],
            statuses: [],
            locations: [],
            isDspActive: true,
            dsps: dsp ? [dsp.dsp_id] : [],
            fromDate: new Date().setHours(0, 0, 0, 0),
            toDate: new Date(),
            isFilterByServiceTime: false,
            searchQuery: "",
            pageSize: 15,
            pageNumber: 1
        }
    })
    const [allRequestCount, setAllRequestCount] = useState(0)
    const [view, setView] = useState("tableView");
    const [statusWiseRequestsCount, setStatusWiseRequestsCount] = useState([])
    const [requestsGraphData, setrequestsGraphData] = useState([]);
    const navigate = useNavigate();

    const getAllRequestsFunc = async () => {
        setLoading(true);
        try {
            const data = await getAllRequests({ ...filters, isDspActive: true, services: ["Spraying service"] }, filters.isFilterByServiceTime)
            setRequests(data?.requests)
            setAllRequestCount(data?.requests[0]?.total_count)
            setLoading(false);
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, "ERROR")
            return
        }
    }

    const getStatusWiseRequestCountFunc = async () => {
        try {
            const statusRequestCount = await getOrdersStatsAPI({ ...filters, isDspActive: true, services: ["Spraying service"] })
            let statsInfo = statusRequestCount?.stats?.map(s => {
                return {
                    status: s.order_status,
                    count: s.total_orders,
                    amount: s.total_amount,
                };
            }).filter(stat => stat.status !== 'Total Orders');
            setStatusWiseRequestsCount(statsInfo)
            return
        } catch (error) {
            Popup.alert("Error", error, "ERROR")
            return
        }
    }

    const filterChanged = async () => {
        try {
            setLoading(true)
            await getStatusWiseRequestCountFunc()
            await getRequestsGraphFunc()
            await getAllRequestsFunc()
            setLoading(false)
        } catch (e) {
            setLoading(false)
            Popup.alert("Error", e, "ERROR")
        }
    }

    const getRequestsGraphFunc = async () => {
        try {
            const data = await getRequestsCountInInterval(
                { ...filters, pageNumber: undefined, pageSize: undefined, services: ["Spraying service"], isDspActive: true },
                filters.isFilterByServiceTime,
            );
            console.log("data : ", data);
            setrequestsGraphData(data?.requests)
        } catch (err) {
            console.log("err", err);
        }
    };

    useEffect(() => {
        filterChanged()
        localStorage.setItem("requestFilters", JSON.stringify(filters))
    }, [filters, navigate, dsp])

    return (
        <MainContentWrapper
            containerStyle={{
                padding: "36px 53px"
            }}
        >
            <StatusCountContainer
                requestsStatuses={statusWiseRequestsCount}
            />
            <InteractiveGraph
                showZoom
                data={requestsGraphData}
                lineType={0}
            />
            <AdminRequestTable
                tabelContainerHeight={"570px"}
                pageNumber={filters.pageNumber}
                requests={requests}
                onFilterChange={(filterToBeApply) => {
                    const updatedFilters = { ...filters, ...filterToBeApply, pageNumber: filterToBeApply.pageNumber ?? 1 }
                    setFilters(updatedFilters)
                }}
                isHideDSPFilter={dsp ? true : false}
                filters={filters}
                loading={loading}
                setLoading={setLoading}
                totalCount={requests[0]?.total_count || 0}
                pageSize={filters.pageSize}
                allCount={allRequestCount}
                onClickRow={(request) => {
                    request = { ...request, serviceType: request?.serviceType?.props?.content }
                    navigate(`${PDRL_SUCCESS_ENGI_ROUTES.REQUESTDETAILS}`, { state: { request: request } })
                }}
                view={view}
                setView={setView}
            />
        </MainContentWrapper>
    )
}

export default Requests