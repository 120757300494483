import React, { useEffect, useState } from "react";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import Popup from "../../../ReusableFunctions/Popup";
import {
  getAllRequests,
  getRequestsGraphStats,
} from "../../../apis/adminAPIs/requests";
import StatusCountAndAmountContainer from "./SubComponents/StatusCountAndAmountContainer";
import { useNavigate } from "react-router";
import { PDRL_FINANCE_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from "../../../routes";
import { getOrdersStatsAPI } from "../../../apis/adminAPIs/finance/orders";
import AdminRequestTable from "../../../AdminComponents/Requests/SubComponents/AdminRequestTable";
import CustomGraph from "./SubComponents/OrdersGraph";
import InteractiveGraph from "./SubComponents/OrdersGraph";
import { PAYMENT_STATUSES } from "../../../appConstants";
import useWindowSize from "../../../hooks/useWindowSize";
import formatNumber from "../../../ReusableFunctions/convertLongNumToShort";


const Orders = ({ dsp }) => {
  const [loading, setLoading] = useState();
  const [requests, setRequests] = useState([]);
  const {currentWidth}=useWindowSize();
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem("requestFilters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      return {
        ...parsedFilters,
        fromDate: parsedFilters.fromDate
          ? new Date(parsedFilters.fromDate)
          : null,
        toDate: parsedFilters.toDate ? new Date(parsedFilters.toDate) : null,
      };
    }
    return {
      services: [],
      statuses: [],
      locations: [],
      isDspActive: true,
      dsps: dsp ? [dsp.dsp_id] : [],
      // fromDate: new Date().setHours(0, 0, 0, 0),
      // toDate: new Date(),
      isFilterByServiceTime: false,
      searchQuery: "",
      pageSize: 15,
      pageNumber: 1,
    };
  });
  const [allRequestCount, setAllRequestCount] = useState(0);
  const [view, setView] = useState("tableView");
  const [requestsGraphData, setrequestsGraphData] = useState([]);
  const [statusWiseRequestsAmount, setStatusWiseRequestsAmount] = useState([]);
  const navigate = useNavigate();

  const getAllRequestsFunc = async () => {
    setLoading(true);
    try {
      const data = await getAllRequests(
        { ...filters, isDspActive: true, services: ["Spraying service"] },
        filters.isFilterByServiceTime
      );
      for(let i in data.requests){
        data.requests[i].total_amount_filtered_requests = formatNumber(Number(data.requests[i].total_amount_filtered_requests))
      }
      setRequests(data?.requests);
      setAllRequestCount(data?.requests[0]?.total_count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Popup.alert("Error", err, "ERROR");
      return;
    }
  };

  const getStatusWiseRequestCountFunc = async () => {
    try {
      const statusRequestCount = await getOrdersStatsAPI(filters);
      for(let i in statusRequestCount?.stats){
        statusRequestCount.stats[i].total_amount = formatNumber(Number(statusRequestCount?.stats[i].total_amount))
      }
      setStatusWiseRequestsAmount(statusRequestCount.stats);

      // console.log(statusRequestCount)
      return;
    } catch (error) {
      Popup.alert("Error", error, "ERROR");
      return;
    }
  };

  const filterChanged = async () => {
    try {
      setLoading(true);
      await getStatusWiseRequestCountFunc();
      await getRequestsGraphFunc();
      await getAllRequestsFunc();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Popup.alert("Error", e, "ERROR");
    }
  };

  const checkStatusExistsInFilters = (filtersStatuses, status) => {
    return filtersStatuses.includes(status);
  };

  const getRequestsGraphFunc = async () => {
    try {
      const data = await getRequestsGraphStats(
        { ...filters, pageNumber: undefined, pageSize: undefined, services: ["Spraying service"], isDspActive: true },
        filters.isFilterByServiceTime,
      );
      // console.log("data.requests",data.requests);
      setrequestsGraphData(data?.requests)
      // console.log(requestsGraphData)
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    filterChanged();
    localStorage.setItem("requestFilters", JSON.stringify(filters));
  }, [filters, navigate]);


  return (
    <MainContentWrapper
      containerStyle={{
        padding: "36px 53px",
        width:"100%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <StatusCountAndAmountContainer
          requestsStatuses={statusWiseRequestsAmount}
          statusFilters={filters?.statuses}
          callback={(status) => {
            let updatedStatuses;
            if (status === "Total Orders") {
              setFilters((prevFilters) => ({
                ...prevFilters,
                fromDate: null,
                toDate: null,
                pageNumber: 1,
                services: [],
                statuses: [],
                locations: [],
                isDspActive: true,
                dsps: dsp ? [dsp.dsp_id] : [],
                paymentMethods : null,
                isFilterByServiceTime: false,
                searchQuery: ""
              }));
              return;
            }
            if (checkStatusExistsInFilters(filters.statuses, status)) {
              updatedStatuses = filters.statuses.filter((s) => s !== status);
            } else {
              updatedStatuses = [status];
            }
            setFilters((prevFilters) => ({
              ...prevFilters,
              statuses: updatedStatuses,
              isFilterByServiceTime: updatedStatuses?.length > 0 ? false : prevFilters.isFilterByServiceTime,
              paymentMethods: updatedStatuses && updatedStatuses.length === 1 && updatedStatuses[0] === PAYMENT_STATUSES.PAID ? prevFilters.paymentMethods : null
            }));
          }}
        />
        <InteractiveGraph
          showZoom
          data={requestsGraphData}
          lineType={0}
        />
        <AdminRequestTable
          title={"Orders"}
          hideTypeofDsp={true}
          hideServices={true}
          tabelContainerHeight={"400px"}
          pageNumber={filters.pageNumber}
          requests={requests}
          onFilterChange={(filterToBeApply) => {
            const updatedFilters = {
              ...filters,
              ...filterToBeApply,
              pageNumber: filterToBeApply.pageNumber ?? 1,
            };
            setFilters(updatedFilters);
          }}
          isHideDSPFilter={dsp ? true : false}
          filters={filters}
          loading={loading}
          setLoading={setLoading}
          totalCount={requests[0]?.total_count || 0}
          pageSize={filters.pageSize}
          allCount={allRequestCount}
          totalOrdersValue={statusWiseRequestsAmount?.find(st => st.order_status === "Total Orders")?.total_amount}
          onClickRow={(request) => {
            request = {
              ...request,
              serviceType: request?.serviceType?.props?.content,
            };
            navigate(`${PDRL_FINANCE_ROUTES.REQUESTDETAILS}`, {
              state: { request: request },
            });
          }}
          view={view}
          isOrderTable={true}
          setView={setView}
        />
      </div>
    </MainContentWrapper>
  );
};

export default Orders;
