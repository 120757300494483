import { buildUrl, getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getDronePilots = () => {
  const getDronePilots = SERVER_URLS.BACKEND_SERVER + "/dsp/dronePilots";
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getDronePilots, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while getting drone pilots.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage ||
          "Something went wrong while getting drone pilots.",
      });
    }
  });
};

export const deleteDronePilot = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        `${SERVER_URLS.BACKEND_SERVER}/dsp/dronePilots/` + id,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(data?.message || "Something went wrong while deleting pilot");
      }
    } catch (error) {
      reject("Something went wrong while while deleting pilot");
    }
  });
};

export const getDronePilot = (pilotId) => {
  const getDronePilot =
    SERVER_URLS.BACKEND_SERVER + `/dsp/dronePilots/${pilotId}`;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getDronePilot, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while getting drone pilot.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage ||
          "Something went wrong while getting drone pilot.",
      });
    }
  });
};

export const addDronePilot = (dronePilot) => {
  const addDronePilot =
    SERVER_URLS.BACKEND_SERVER + "/dsp/dronePilots" + `?dspId=${getDSPId()}`;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(addDronePilot, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          dronePilot,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while adding drone pilot.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage ||
          "Something went wrong while adding drone pilot.",
      });
    }
  });
};

export const updateDronePilot = (dronePilot) => {
  const updateDronePilot =
    SERVER_URLS.BACKEND_SERVER + "/dsp/dronePilots" + `?dspId=${getDSPId()}`;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(updateDronePilot, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          dronePilot,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while updating drone pilot.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage ||
          "Something went wrong while updating drone pilot.",
      });
    }
  });
};

export const getAssignCount = (selectedDate, pilotId, droneId) => {
  const getAssignedCount =
    SERVER_URLS.BACKEND_SERVER +
    `/dsp/requests/getAssignedCount?selectedDate=${selectedDate}${
      pilotId ? `&&pilotId=${pilotId}` : ""
    }${droneId ? `&&droneId=${droneId}` : ""}`;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getAssignedCount, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while getting drone pilots.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage ||
          "Something went wrong while getting drone pilots.",
      });
    }
  });
};

export const getDronePilotsLatestLocations = () => {
  const getDronePilots =
    SERVER_URLS.BACKEND_SERVER + "/dsp/dronePilots/locations/map";
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getDronePilots, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while getting drone pilots.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage ||
          "Something went wrong while getting drone pilots.",
      });
    }
  });
};

export const getPilotLocationsHistoryDSP = async (pilotId, fromDate, toDate) => {
  try {
    const token = getToken();
    const url = buildUrl(
      SERVER_URLS.BACKEND_SERVER + "/dsp/dronePilots/locations/history",
      {
        pilotId: pilotId ? pilotId : undefined,
        fromDate: fromDate ? fromDate : undefined,
        toDate: toDate ? toDate : undefined,
      }
    );
    console.log("url",url)
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (!response.ok) {
      throw (
        res.message || "Something went wrong while getting pilot locations history  "
      );
    }
    return res.locationsHistory;
  } catch (err) {
    throw err;
  }
};
