import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../../Components/MainContentWrapper'
import ListInActive from '../../../assets/listInactive.png'
import ListActive from '../../../assets/listActive1.png'
import MapActive from '../../../assets/mapActive1.png'
import MapInactive from '../../../assets/map.png'
import Map from '../../LeafletMap/Map'
import { MAP_URLS } from '../../../appConstants'
import roadMapView from '../../../assets/roadMapView.png'
import sateliteView from '../../../assets/sateliteView.png'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import { getDronePilotsLatestLocations } from '../../../apis/dronePilots'
import Loading from '../../../ReusableFunctions/Loader'
import Popup from '../../../ReusableFunctions/Popup'
import MarkerIcon from "../../../assets/pilot.svg";
import AddDronePilot from './AddDronePilot'
import Plus from "../../../assets/Plus.svg";
import { Button } from '../../../Components/Helper'
import useWindowSize from '../../../hooks/useWindowSize'

let baseLayer
export default function ViewPilotsOnMap({ view, setView, openEditPilot, setOpenEditPilot, indianStates, handleOnPilotAdd }) {
    const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer)
    const [pilots, setPilots] = useState([]);
    const { currentWidth } = useWindowSize()
    const [center, setCenter] = useState(
        { lat: 19.993982, lng: 73.790416 }
    );
    const [map, setMap] = useState(null)

    const markerIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [32, 38],
    });

    const initMap = (map) => {
        setMap(map)
    };

    const switchBaseLayer = () => {
        const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite"
        setActiveBaseLayer(activeLayer)
        if (baseLayer) {
            baseLayer.setUrl(activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        }
    }
    const clearMap = () => {
        map.eachLayer((layer) => {
            if (layer instanceof L.Marker || layer instanceof L.MarkerClusterGroup) {
                map.removeLayer(layer);
            }
        });
    };

    const initData = async () => {
        Loading.set(true);
        try {
            const res = await getDronePilotsLatestLocations();
            setPilots(res?.pilots);
            clearMap();
            if (res?.pilots?.length > 0) {
                drawPilotsOnMap(res?.pilots);
            } else {
                setPilots([]);
            }
            Loading.set(false);
        } catch (e) {
            Loading.set(false);
            console.log(e)
            Popup.alert("ERROR", e?.message || "Something went wrong while displaying information", "ERROR")
        }
    }

    const drawPilotsOnMap = (pilots) => {
        var markerOptions = {
            clickable: true,
            draggable: false,
            icon: markerIcon,
        };

        let clusterActive = new L.markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            iconCreateFunction: (cluster) => {
                let count = cluster.getChildCount()
                return L.divIcon({
                    html: '<div style="background-color:green; color:white;"><span>' + count + '</span></div>',
                    className: 'marker-cluster marker-cluster-medium active_cluster ',
                    iconSize: L.point(45, 45)
                });
            }

        })
        const newPilots = pilots.map(u => {
            if (!u.pilot_location) {
                return;
            }
            const center = { lat: u?.pilot_location?.lat, lng: u?.pilot_location?.lng };

            let marker1 = new L.Marker(center, markerOptions)
            marker1.on('click', function () {
                const currentZoom = Math.min(map.getZoom() + 1, map.getMaxZoom());
                map.flyTo(center, currentZoom);
            });
            const tooltipContent = `
                <div style="display:flex; height:110px; min-width:200px; ">
                    <div style="padding:10px;">
                        <div>User name: <span style="font-weight:600;">${u.first_name + ' ' + u.last_name} </span></div>
                        <div>Email: <span style="font-weight:600;">${u?.email} </span></div>
                        <div>State: <span style="font-weight:600;">${u?.state} </span></div>
                        <div>district: <span style="font-weight:600;">${u?.district} </span></div>   
                        <div>city: <span style="font-weight:600;">${u?.city} </span></div>   
                    </div>
                </div>
                `
            marker1.bindTooltip(tooltipContent, {
                className: 'location-tooltip',
                permanent: false,
            });
            clusterActive.addLayer(marker1)
            return { ...u, markerLeafletLayer: marker1 }
        })
        setPilots(newPilots);
        map?.addLayer(clusterActive)
    }

    useEffect(() => {
        if (map) {
            initData();
        }
    }, [map]);
    return (
        <MainContentWrapper header={`Pilots (${pilots?.length > 0 ? pilots?.length : 0})`} rightContent={
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "2rem" }} >
                <div>
                    {pilots && pilots?.length > 0 &&
                        <Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
                            setOpenEditPilot(true)
                        }} style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }} />}
                </div>
                <div style={{ width: "100px", height: currentWidth < 1367 ? "38px" : "", display: "flex", marginLeft: "5px", borderRadius: "5px", border: "1px solid gray", alignItems: "center" }}>
                    <div
                        onClick={() => {
                            setView("grid")
                        }}
                        style={{ cursor: "pointer", padding: "1px 15px ", width: "50%", overflow: "hidden" }}
                        className={`${view === "grid" ? 'pilots_list_view_wrapper' : ''}`}
                    >
                        <div className='img'>
                            <img color='white' style={{ marginBottom: "4px" }} width={20} src={view === "grid" ? ListActive : ListInActive} />
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setView("map")
                        }}
                        style={{ cursor: "pointer", padding: "1px 15px ", width: "50%", overflow: "hidden" }}
                        className={`${view === 'map' ? 'pilot_map_view_wrapper' : ''}`}>
                        <div className='img'>
                            <img style={{ marginBottom: currentWidth > 1366 ? "6px" : "4px" }} width={20} src={view === 'map' ? MapActive : MapInactive} />
                        </div>
                    </div>
                </div>

            </div>
        }>
            {openEditPilot &&
                <AddDronePilot
                    open={openEditPilot}
                    onClose={() => { setOpenEditPilot(false) }}
                    pilotId={null}
                    onPilotAdd={(pilot) => {
                        handleOnPilotAdd(pilot)
                        setOpenEditPilot(false)
                    }}
                    indianStates={indianStates}
                />
            }
            <div style={{ width: "100%", height: "100%", position: 'relative', zIndex: 9, }}>
                <Map
                    setBaseLayerToState={(bL) => {
                        baseLayer = bL
                    }}
                    initZoom={6}
                    handleMapClick={() => { }}
                    maxZoom={23}
                    style={{
                        height: "100%",
                        borderRadius: "10px",
                        boxShadow: '0px 0px 8.9px 3px rgba(114, 114, 114, 0.15)',

                    }}
                    mapInitialized={(map) => {
                        initMap(map);
                    }}
                />
                <div style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', height: '25px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '999', position: "absolute", right: "10px", bottom: '24px' }}
                    onClick={switchBaseLayer}>
                    <img style={{ width: '80px', height: '25px', borderRadius: '4px' }} src={activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
                </div>
            </div>
        </MainContentWrapper>
    )
}
