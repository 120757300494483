import React, { useEffect, useState } from 'react'
import { getOnboardedUsers,getRequestsIncentives,getAllSettlements } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/stores'
import Popup from '../../ReusableFunctions/Popup'
import MainContentWrapper from '../../Components/MainContentWrapper'
import TableView from '../../ReusableComponents/TableView/TableView'

export default function ViewOnBoardedUsers({
    store
}) {
    const [onBoardedUsers, setOnBoardedUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
    })
    const getOnBoardedUsersFunc = async () => {
        try {
            setIsLoading(true);
            let users = await getOnboardedUsers(store?.id, filters.pageNumber, filters.pageSize);
            setOnBoardedUsers(users);
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            Popup.alert("ERROR", "Something went wrong while getiing the onboarded users")
            console.log("err", err);
        }
    }

    useEffect(() => {
        getOnBoardedUsersFunc()
    }, [filters])
    return (
        <MainContentWrapper header={`Users (${onBoardedUsers[0]?.users_count || 0})`} rightContent={<div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
        </div>
        }>
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={isLoading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(user) => { }}
                    columns={[
                        { Header: "Name.", accessor: "user_name", width: "8%" },
                        { Header: "Email", accessor: "user_email", width: "8%" },
                        { Header: "Mobile No", accessor: "user_mobile", width: "8%" },
                        { Header: "Type", accessor: "type", width: "8%" },
                        { Header: "Note", accessor: "note", width: "15%" },
                        { Header: "Amount", accessor: "amount", width: "8%" },
                        { Header: "Date Added", accessor: "time", isDate: true, format: "dd/mm/yyyy", width: "8%" }
                    ]}
                    count={onBoardedUsers[0]?.users_count}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={onBoardedUsers}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    )
}
