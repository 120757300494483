import React, { useEffect, useState } from "react";
import { isUserLoggedIn, setLogin, login } from "../../apis/authentication";
import { Navigate, useNavigate } from "react-router";
import MailIcon from "../../assets/mail icon.svg";
import ShowPassword from "../../assets/Eye.svg";
import HidePassword from "../../assets/EyeSlash.svg";
import LeftSVG from "../../assets/keft.svg";
import RightSVG from "../../assets/right.svg";
import BhumitLogo from "../../assets/bhumeet.svg";
import { Button } from "../../Components/Helper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../reducers/userReducer";
import { BHUMEET_TEAM_ROLES, GLOBAL_STYLES } from "../../appConstants";
import ForgotPassword from "./SubComponents/ForgotPassword";
import { PDRL_ADMIN_ROUTES } from "../../routes";
import ChangePasswordWithOTP from "../Profile/SubComponents/ProfileSetup/SubComponents/ChangePasswordWithOTP";
import Form from "../../ReusableComponents/Form";
import InputField from "../../ReusableComponents/InputField";
import { emailValidation, passwordValidation } from "../../utils/inputValidations";
const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user)
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ type: "", msg: "" })
  const [openForgotPasswordPopup, setOpenForgotPasswordPopup] = useState(false)
  const [openChangePassPopup, setOpenChangePassPopup] = useState(false)
  const [inputedMail, setInputedMail] = useState('')

  const handleLogin = async (username, password) => {
    localStorage.removeItem("user-details");
    localStorage.removeItem("company-details");
    localStorage.removeItem("phone-verified");
    localStorage.removeItem("email-verified");
    setLoading(true);
    try {
      // get login data from API
      const user = await login(username, password);
      if (user) {
        dispatch(setUser(user));
        await setLogin(user);
      }
    } catch (e) {
      setLoading(false);
      setError({ type: 'authentication_failure', msg: e });
    }
  };

  useEffect(() => {
    if (error.type === 'authentication_failure')
      setTimeout(() => {
        setError({ type: "", msg: "" });
      }, 5000);
  }, [error.type, error.msg]);

  useEffect(() => {
    if (user) {
      if (user.is_pdrl_admin) {
        if (user.bhumeet_role == BHUMEET_TEAM_ROLES.ADMIN.NAME) {
          navigate(PDRL_ADMIN_ROUTES.ADMIN_DASHBOARD)
        } else if (user.bhumeet_role == BHUMEET_TEAM_ROLES.FINANCE.NAME) {
          navigate(PDRL_ADMIN_ROUTES.FINANCE_DASHBOARD)
        } else if (user.bhumeet_role == BHUMEET_TEAM_ROLES.CUSTOMER_SUPPORT_ENGINEER.NAME) {
          navigate(PDRL_ADMIN_ROUTES.SUCCESS_ENGI_DASHBOARD)
        } else if (user.bhumeet_role == BHUMEET_TEAM_ROLES.MARKETING.NAME) {
          navigate(PDRL_ADMIN_ROUTES.MARKETINGDASHBOARD)
        }
        localStorage.setItem("dsp_id", "admin");
      } else {
        console.log(1, user)
        if (user?.dsp?.is_verified) {
          console.log(2)
          navigate("/")
        } else {
          console.log(3)
          navigate("/profile")
        }
      }
    }
  }, [user])

  return <div style={{ width: "100%", height: "100%", background: "" }}>
    <ForgotPassword
      open={openForgotPasswordPopup}
      setOpenChangePassPopup={(showPopup, email) => {
        setOpenChangePassPopup(showPopup);
        setInputedMail(email)
      }}
      onClose={(email) => {
        setOpenForgotPasswordPopup(false)
      }}
    />
    <ChangePasswordWithOTP
      open={openChangePassPopup}
      showForgotPasswordPopup={(show) => setOpenForgotPasswordPopup(show)}
      onClose={() => {
        setOpenChangePassPopup(false)
      }}
      userEmail={inputedMail}
    />
    <div style={{ position: "absolute", left: "15%", width: "180px", top: "5%" }}>
      <img src={BhumitLogo} alt="BhuMeet-Logo" style={{ width: "100%" }} />
    </div>
    <div
      style={{
        position: "absolute",
        left: "0px",
        width: "220px",
        bottom: "0",
      }}
    >
      <img src={LeftSVG} alt="Left-Design" style={{ width: "100%" }} />
    </div>
    <div
      style={{ position: "absolute", right: "0px", width: "220px", top: "0" }}
    >
      <img src={RightSVG} alt="Right-Design" style={{ width: "100%" }} />
    </div>

    <div id="login-container">
      <div
        style={{
          padding: "34px 36px 34px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >

        <div
          style={{
            fontSize: "19px",
            fontWeight: "700",
            color: "#1E1E1E",
            marginBottom: "10px",
            letterSpacing: "0.3px",
          }}
        >
          Log in
        </div>
        <Form
          onSubmitForm={(data) => {
            handleLogin(data.username, data.password)
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            marginTop: '5px'
          }}
        >
          {(onSubmit) => (
            <>
              <div style={{ width: "100%" }}>
                <InputField
                  type="email"
                  id="username"
                  name={"username"}
                  placeholder="Email Address"
                  icon={MailIcon}
                  iconStyle={{ width: "20px" }}
                  style={{
                    height: "35px",
                    fontSize: "15px",
                    fontWeight: 400,
                    letterSpacing: "0.3px"
                  }}
                  validation={emailValidation}
                  containerStyle={{ marginBottom: "20px" }}
                />
                <InputField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name={"password"}
                  placeholder="Password"
                  onClickIcon={() => setShowPassword(!showPassword)}
                  icon={showPassword ? ShowPassword : HidePassword}
                  iconStyle={{ width: "20px" }}
                  style={{
                    height: "35px",
                    fontSize: "15px",
                    fontWeight: 400,
                    letterSpacing: "0.3px",
                  }}
                  validation={{
                    ...passwordValidation,
                    minLength: {
                      value: '',
                      message: '',
                    },
                    pattern: {
                      value: '',
                      message: '',
                    },
                  }}
                />
                <div style={{ display: 'flex', alignItems: "baseline", justifyContent: "space-between", height: "18px" }}>
                  <div
                    style={{
                      color: GLOBAL_STYLES.ERROR,
                      fontSize: "12px",
                    }}
                  >
                    {error.type === "authentication_failure" ? error.msg : ''}
                  </div>
                  <div
                    style={{
                      textDecoration: "underline",
                      textAlign: "right",

                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#3B3B3B",
                      cursor: "pointer"
                    }}
                    onClick={() => setOpenForgotPasswordPopup(true)}
                  >
                    Forgot Password?
                  </div>
                </div>
              </div>
              <div>
                <Button
                  text={loading ? "Logging..." : "Log in"}
                  type="submit"
                  onClick={() => {
                    onSubmit()
                  }}
                  isDisabled={loading}
                  style={{
                    width: "100%",
                    borderRadius: "2px",
                    border: "1px",
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "17px 0px",
                  }}
                />
                <div
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "#3B3B3B",
                    }}
                  >
                    Don't have an account ?{" "}
                    <Link
                      to={"/signup"}
                      style={{ color: GLOBAL_STYLES.BG_ACTIVE }}
                    >
                      Sign up
                    </Link>
                  </span>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  </div>
};

export default Login;
