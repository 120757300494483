import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { deleteRewardProduct, getRewardProducts } from '../../apis/BhuMeetTeams/Oparations/RewardProducts';
import Popup from '../../ReusableFunctions/Popup';
import TableView from '../../ReusableComponents/TableView/TableView';
import Plus from "../../assets/Plus.svg";
import { Button } from '../../Components/Helper';
import AddProductModal from './AddProductModal';

export default function RewardProducts() {
    const [loading, setLoading] = useState(false);
    const [rewardProducts, setRewardProducts] = useState([])
    const [rewardProductsCount, setRewardProductsCount] = useState(0)
    const [isOpenAddProductModal, setIsOpenAddProductModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [filters,setFilters] = useState({
        pageSize:15,
        pageNumber:1,
    });

    const getAllProductsFunc=async()=>{
        try{
        setLoading(true);
        const products = await getRewardProducts(filters);
        setRewardProducts(products);
        setRewardProductsCount(products[0]?.product_count)
        setLoading(false)
        }catch(err){
            setLoading(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(()=>{
        getAllProductsFunc();
    },[filters])

    const handleOnProductAdd=async()=>{
         await getAllProductsFunc();
    }
        const onProductEdit=(product)=>{
            setIsOpenAddProductModal(true)
            setSelectedProduct(product);

        }
        const onProductDelete=async(product)=>{
            try{
                setLoading(true);
                await deleteRewardProduct(product?.id);
                Popup.alert("Product", "Reward product deleted successfully!", 'SUCCESS',()=>{})
                setLoading(false);
                getAllProductsFunc();
            }catch(err){
                setLoading(false);
                Popup.alert("Error", err, 'ERROR')
            }
    
        }
console.log("rewardCount",rewardProductsCount);
  return (
    <MainContentWrapper header={`Products (${rewardProductsCount})`} rightContent={
    <Button
        type="submit"
        icon={Plus}
        text={"Add New"}
        style={{
            height:"35px"
        }}
        onClick={() => {
           setSelectedProduct(null);
           setIsOpenAddProductModal(true)
        }}
    />} >
         {isOpenAddProductModal && (
            <AddProductModal
            open={isOpenAddProductModal}
            onClose={() => {
                setIsOpenAddProductModal(false)
                setSelectedProduct(null)
            }}
            selectedProduct={selectedProduct || null}
            onProductAdd={(product)=>{
             handleOnProductAdd(product);
             setIsOpenAddProductModal(false);
            }}
            />
         )}
        
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                showSrNo
                    loadingHeight={"500px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(data) => {
                        // setSelectedOrder(data);
                    }}
                    columns={[
                        { Header: "Name", accessor: "name", width: "10%", },
                        { Header: "Points", accessor: "points", width: "10%" },
                        { Header: "Available", accessor: "available", width: "10%" },
                        { Header: "Created Date", accessor: "date_created", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                    ]}
                    count={rewardProductsCount}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={rewardProducts.map((p)=>({
                        ...p,
                        available:p.is_available===true? "Yes":"No"
                    }))}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                    activities={{ onClickEdit: onProductEdit, canEdit: true,onClickDelete: onProductDelete}}
                />
            </div>
        </MainContentWrapper>
  )
}
