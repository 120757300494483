import { orderBy } from "lodash";
import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";


export const getAllRequests = ({ isDspActive, services, dsps, statuses, locations, orderBy, orderType, userId, fromDate, toDate, pilotId, searchQuery, pageSize, pageNumber, paymentMethods, onlyAppliedBhuMeetCouponsRequests }, isOrderByServiceTime) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data;
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/aggregator/request?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}&searchQuery=${searchQuery || ""}` +
                `${(isDspActive != null || isDspActive != undefined) ? `&isDspActive=${isDspActive}` : ""}` +
                `${services?.length > 0 ? `&services=${encodeURIComponent(JSON.stringify(services))}` : ""}` +
                `${dsps?.length > 0 ? `&dsps=${encodeURIComponent(JSON.stringify(dsps))}` : ""}` +
                `${statuses?.length > 0 ? `&statuses=${encodeURIComponent(JSON.stringify(statuses))}` : ""}` +
                `${locations?.length > 0 ? `&locations=${encodeURIComponent(JSON.stringify(locations))}` : ""}` +
                `${orderBy ? `&orderBy=${orderBy}` : ""}` +
                `${orderType ? `&orderType=${orderType}` : ""}` +
                `${userId ? `&userId=${userId}` : ""}` +
                `${fromDate ? `&fromDate=${new Date(fromDate).toISOString()}` : ""}` +
                `${toDate ? `&toDate=${new Date(toDate).toISOString()}` : ""}` +
                `${pilotId ? `&pilotId=${pilotId}` : ""}` +
                `${paymentMethods ? `&paymentMethods=${JSON.stringify(paymentMethods)}` : ""}` +
                `${onlyAppliedBhuMeetCouponsRequests ? `&onlyAppliedBhuMeetCouponsRequests=true` : ""}` +
                `${isOrderByServiceTime ? `&isOrderByServiceTime=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                data = await response.json();
            }
            if (response.ok && response.status === 200) {
                resolve({ requests: data.requests });
            } else if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                reject(data?.message || "Something went wrong while getting requests.");
            }
        } catch (error) {
            reject("Something went wrong while getting requests.");
        }
    });
}

export const getRequestsGraphStats = ({ isDspActive, services, dsps, statuses, locations, orderBy, orderType, userId, fromDate, toDate, pilotId, searchQuery, pageSize, pageNumber, paymentMethods, onlyAppliedBhuMeetCouponsRequests }, isOrderByServiceTime) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data;
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/aggregator/request/getRequestsGraphStats?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}&searchQuery=${searchQuery || ""}` +
                `${(isDspActive != null || isDspActive != undefined) ? `&isDspActive=${isDspActive}` : ""}` +
                `${services?.length > 0 ? `&services=${encodeURIComponent(JSON.stringify(services))}` : ""}` +
                `${dsps?.length > 0 ? `&dsps=${encodeURIComponent(JSON.stringify(dsps))}` : ""}` +
                `${statuses?.length > 0 ? `&statuses=${encodeURIComponent(JSON.stringify(statuses))}` : ""}` +
                `${locations?.length > 0 ? `&locations=${encodeURIComponent(JSON.stringify(locations))}` : ""}` +
                `${orderBy ? `&orderBy=${orderBy}` : ""}` +
                `${orderType ? `&orderType=${orderType}` : ""}` +
                `${userId ? `&userId=${userId}` : ""}` +
                `${fromDate ? `&fromDate=${new Date(fromDate).toISOString()}` : ""}` +
                `${toDate ? `&toDate=${new Date(toDate).toISOString()}` : ""}` +
                `${pilotId ? `&pilotId=${pilotId}` : ""}` +
                `${paymentMethods ? `&paymentMethods=${JSON.stringify(paymentMethods)}` : ""}` +
                `${onlyAppliedBhuMeetCouponsRequests ? `&onlyAppliedBhuMeetCouponsRequests=true` : ""}` +
                `${isOrderByServiceTime ? `&isOrderByServiceTime=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                data = await response.json();
            }
            if (response.ok && response.status === 200) {
                resolve({ requests: data.graph });
            } else if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                reject(data?.message || "Something went wrong while getting requests.");
            }
        } catch (error) {
            reject("Something went wrong while getting requests.");
        }
    });
}

export const getAllRequestsCount = ({ fromDate, toDate, isDspActive }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/request/getRequestsCount?temp=true${isDspActive ? `&is_dsp_active=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.requestsCount);
            } else {
                reject(
                    data?.message || "Something went wrong while getting requests."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting requests.");
        }
    });
}

export const deleteRequest = (requestId) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (requestId) {
                const token = getToken();
                const response = await fetch(
                    SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/operations/requests/deleteRequest?requestId=${requestId}`,
                    {
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const result = await response.json();
                if (response.ok) {
                    resolve(result.message);
                } else {
                    reject(
                        result?.message || "Something went wrong while deleting requests."
                    );
                }
            } else {
                reject("Request Id not found.");
            }
        } catch (error) {
            reject("Something went wrong while deleting requests.");
        }
    });
}