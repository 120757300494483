import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from 'react-router';
import { getUserDetails } from "../../../apis/users";
import Popup from "../../../ReusableFunctions/Popup";
import Loading from "../../../ReusableFunctions/Loader";
import { reduceLongName } from "../../../ReusableFunctions/reusableFunctions";
import { getRequests, getRequestsCount } from "../../../apis/requests";
import RequestTable from "../../../features/MyRequests/SubComponents/RequestTable/RequestTable";
import UserLogo from "../../../assets/userlogo.png"
import Tippy from "@tippyjs/react";
import CustomTooltip from "../../../ReusableComponents/CustomTooltip";
import MainContentWrapper from "../../../Components/MainContentWrapper";
import loactionRed from "../../../assets/loactionRed.svg";
import icard from "../../../assets/icard.svg";
import email from "../../../assets/email.svg";
import mobile from "../../../assets/mobile.svg";
import hat from "../../../assets/hat.svg";
import map from "../../../assets/map.svg";
import dGreen from "../../../assets/dGreen.svg";
import UserDetailsWrapper from "../../../Components/UserDetails/UserDetailsWrapper";
import SingleInfo from "../../../Components/UserDetails/SingleInfo";
import dateFormat from "dateformat";
import UserRequestTable from "../../Requests/SubComponents/UserRequestTable";
import LocationOnMap from "../../../ReusableComponents/LocationOnMap";
import AdminRequestTable from "../../Requests/SubComponents/AdminRequestTable";
import { PDRL_SUCCESS_ENGI_ROUTES } from "../../../routes";
import { getAllRequests } from "../../../apis/adminAPIs/requests";

const UserDetails = () => {
    const { state } = useLocation();
    const { search } = useLocation();
    const navigate = useNavigate();

    const dataPerPage = 8
    const params = new URLSearchParams(search)
    const userId = params.get('userId') || state?.user?.id
    const [user, setUser] = useState(null);
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [requestsCount, setRequestsCount] = useState({})
    const [totalRequestCount, setTotalRequestCount] = useState(0)
    const [screenWidth, setScreenWidth] = useState(1366)
    const [filters, setFilters] = useState({
        services: [],
        statuses: [],
        locations: [],
        isDspActive: true,
        dsps: [],
        fromDate: new Date().setHours(0, 0, 0, 0),
        toDate: new Date(),
        isFilterByServiceTime: false,
        searchQuery: "",
        pageSize: 15,
        pageNumber: 1,
    })
    const [searchQuery, setSearchQuery] = useState("")
    const [view, setView] = useState("tableView");
    const [sortingFilters, setsortingFilters] = useState({
        orderBy: "date_created",
        orderType: "DESC"
    })

    const getRequestsCountFunc = () => {
        getRequestsCount(userId)
            .then((res) => {
                const { spraying_requests_count, training_requests_count, survey_requests_count, total } = res.requestsCount
                setRequestsCount({
                    spraying: spraying_requests_count,
                    training: training_requests_count,
                    survey: survey_requests_count,
                    totalRequest: total
                });
            })
            .catch((err) => {
                console.log(err);
                Popup.alert("Error", err.errorMessage, "ERROR");
            });
    }

    // const getRequestsFunc = (pageNumber, searchQuery, filters) => {
    //     setLoading(true)
    //     getRequests(state?.user?.id, filters, dataPerPage, filters.pageNumber, filters.searchQuery)
    //         .then((res) => {
    //             setRequests(res.requests);
    //             setLoading(false);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setLoading(false);
    //             Popup.alert("Error", err.errorMessage, "ERROR");
    //         });
    // };

    // const fetchInitalData = () => {
    //     try {
    //         getRequestsCountFunc()
    //         getRequestsFunc(currentPage, undefined, { ...filters, ...sortingFilters });
    //         Loading.set(false);
    //     } catch (err) {
    //         console.log("err", err);
    //     }
    // }

    useEffect(() => {
        setLoading(true)
        Loading.set(true);
        document.title = "BhuMeet - Users";
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth)
        getRequestsCountFunc();
        // fetchInitalData();
        setUser(() => state?.user)
        Loading.set(false)
    }, [state.user,]);

    const getReq = async () => {
        let data = await getAllRequests({ ...filters, isDspActive: true, services: ["Spraying service"], userId: state?.user?.id, }, filters.isFilterByServiceTime)
        setRequests(data?.requests || [])
        setTotalRequestCount(data?.requests[0]?.total_count)
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getReq()
    }, [filters])

    return (
        <MainContentWrapper header={"Users Details"}>
            <div style={{ width: "100%", height: "calc(100% - 56px)", display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginBottom: "15px", alignItems: "center", gap: screenWidth == 1920 ? "40px" : '28px', width: "100%" }}>
                    <div style={{ width: "33%" }}>
                        <UserDetailsWrapper>
                            <div style={{ display: "flex", flexDirection: "column", height: '100%', justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: screenWidth == 1920 ? "25px" : "18px" }}>
                                    <img className="user-details-first-wrapper-avtar" src={user?.name ? `https://ui-avatars.com/api/?background=727272&color=fff&name=${user?.name.split(" ").join("+")}` : UserLogo} alt="profile pic" />
                                    <div style={{ height: "65px", display: "flex", justifyContent: "center", flexDirection: "column", gap: '3px' }}>
                                        <div style={{ fontSize: screenWidth == "1920" ? "24px" : "17px", color: "#0845C0", fontWeight: "600" }}>
                                            {
                                                user?.name ?
                                                    <Tippy allowHTML={true} content={<CustomTooltip content={user?.name} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                                        <span > {user?.name.length > 18 ? reduceLongName(user?.name, 18) : user?.name}</span>
                                                    </Tippy>
                                                    : <span > {"-"}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: 'center', justifyContent: "left", width: '100%', gap: screenWidth == 1920 ? "100px" : "35px" }}>
                                    <SingleInfo icon={mobile} title={"Mobile No:"} titleStyle={{ fontSize: "11px" }} infoStyle={{ fontSize: "11px" }} iconStyle={{ height: screenWidth == 1920 ? "auto" : "30px" }} info={user?.mobile_number || "-"} />
                                    {/* <SingleInfo icon={email} title={"Email Id:"} titleStyle={{ fontSize: "11px" }} infoStyle={{ fontSize: "11px" }} iconStyle={{ height: screenWidth == 1920 ? "auto" : "30px" }} info={user.email || "-"} /> */}
                                    <SingleInfo icon={icard} title={"Occupation:"} titleStyle={{ fontSize: "11px" }} infoStyle={{ fontSize: "11px" }} iconStyle={{ height: screenWidth == 1920 ? "auto" : "30px" }} info={user?.occupation ?
                                        <Tippy allowHTML={true} content={<CustomTooltip content={user.occupation} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                            <span > {user?.occupation.length > 30 ? reduceLongName(user?.occupation, 30) : user?.occupation}</span>
                                        </Tippy>
                                        : <span > {"-"}</span>}
                                    />
                                </div>
                            </div>
                        </UserDetailsWrapper>
                    </div>
                    <div style={{ width: "33%" }}>
                        <UserDetailsWrapper>
                            <div style={{ display: "flex", flexDirection: "column", height: '100%', justifyContent: "space-between" }}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: '3px' }}>
                                    <div style={{ fontSize: "16px", color: "#3B3B3B", fontWeight: "500" }}>
                                        {`${user?.name}'s total orders (${requestsCount?.totalRequest || 0})`}
                                    </div>
                                    <p style={{ fontSize: '12px', color: "#848484", }}>
                                        {`(Since ${dateFormat(user?.date_created, 'dd mmm yyyy')})`}
                                    </p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "left", alignItems: 'center', width: '100%', gap: screenWidth == 1920 ? "100px" : "30px" }}>
                                    <SingleInfo icon={dGreen} iconStyle={{ height: screenWidth == 1920 ? "auto" : "40px" }} title={"Spraying"} info={requestsCount?.spraying} titleStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#848484", fontWeight: "600" }} infoStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#1E1E1E", fontWeight: "500" }} />
                                    <SingleInfo icon={map} iconStyle={{ height: screenWidth == 1920 ? "auto" : "40px" }} title={"Survey"} info={requestsCount?.survey} titleStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#848484", fontWeight: "600" }} infoStyle={{ fontSize: screenWidth == 1920 ? "20px" : "13px", color: "#1E1E1E", fontWeight: "500" }} />
                                </div>
                            </div>
                        </UserDetailsWrapper>
                    </div>
                    <div style={{ width: "33%", height: "100%" }}>
                        <LocationOnMap
                            lat={Number(state?.user?.lat)} lon={Number(state?.user?.lng)}
                        />
                    </div>
                </div>
                <AdminRequestTable
                    tabelContainerHeight={screenWidth === 1366 ? "310px" : "570px"}
                    pageNumber={1}
                    requests={requests}
                    onFilterChange={(filterToBeApply) => {
                        const updatedFilters = { ...filters, ...filterToBeApply, pageNumber: filterToBeApply.pageNumber ?? 1 }
                        setFilters(updatedFilters)
                    }}
                    isHideDSPFilter={false}
                    filters={filters}
                    loading={loading}
                    setLoading={setLoading}
                    totalCount={totalRequestCount}
                    pageSize={filters.pageSize}
                    allCount={0}
                    onClickRow={(request) => {
                        request = { ...request, serviceType: request?.serviceType?.props?.content }
                        navigate(`${PDRL_SUCCESS_ENGI_ROUTES.REQUESTDETAILS}`, { state: { request: request } })
                    }}
                    view={view}
                    setView={setView}
                />
            </div>
        </MainContentWrapper>)
};

export default UserDetails;
