import React from 'react'
import Toggle from "react-toggle"
import Popup from '../../ReusableFunctions/Popup'
import { getFormatedDate } from '../../ReusableFunctions/reusableFunctions'
import { GLOBAL_STYLES } from '../../appConstants'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { QRCodeSVG } from 'qrcode.react';
import { updateStore } from '../../apis/BhuMeetTeams/Oparations/store'
import { useLocation } from 'react-router-dom'
const ViewStoreDetails = ({ store, callback, }) => {

    const getLocation = useLocation()
    console.log(getLocation)
    const updateStoreFunc = async (store) => {
        try {
            await updateStore({ id: store.id, is_active: store.is_active })
            callback(store);
        } catch (err) {
            Popup.alert("Error", err, 'ERROR')
        }
    }


    const triggerDownloadQRCode = (size) => {
        const qrContainer = document.getElementById('qr-container');
        const svgElement = qrContainer.querySelector('svg');

        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0, size, size);

            const pngUrl = canvas.toDataURL('image/png');

            const a = document.createElement('a');
            a.href = pngUrl;
            a.download = 'qr_code.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        const svgXml = new XMLSerializer().serializeToString(svgElement);
        img.src = 'data:image/svg+xml;base64,' + btoa(svgXml);

    };
    return (
        <MainContentWrapper containerStyle={{
            height: "720px",
        }} >
            <div style={{ height: "100%", background: "rgb(244, 244, 244)", padding: "0px 0px 20px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", background: "#D9D9D9D9", padding: "20px" }}>

                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                        <div style={{ fontSize: "14px" }}>Store Details</div>
                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                            {store.toShow ? <span><label style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={(e) => e.stopPropagation()}>
                                
                                <Toggle
                                    className='admin'
                                    checked={store?.is_active}
                                    icons={false}
                                    onChange={(e) => {
                                        const provider = { ...store, is_active: e.target.checked }
                                        if (e.target.checked)
                                            updateStoreFunc(provider)
                                        else
                                            Popup.alert("Warning", "Are u sure u want to deactivate this account?", "WARNING", () => updateStoreFunc(provider))
                                    }}
                                />
                            </label>
                            <span style={{ color: store.is_active ? "green" : "red", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>{store.is_active ? "Store Active" : "Store Inactive"}</span>
                            </span> : ''}
                            
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ fontWeight: 600, marginBottom: "10px" }}>Store Details</div>

                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr auto", // Ensures a proper 3-column layout
                            gap: "0px",
                            alignItems: "start", // Ensures alignment across rows
                        }}
                    >
                        {/* First and Second Columns */}
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.store_name || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Store No</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.store_code || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.address || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>State</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.state || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>District</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.district || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>City</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.city || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Added Date</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{getFormatedDate(store.date_added) || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Pincode</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.pin_code || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Phone Number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div>{store.mobile_number || "-"}</div>
                        </div>

                        {/* QR Code - Separate Column */}
                        <div id='qr-container'
                            style={{
                                gridColumn: 3, // Ensures it's in the third column
                                gridRow: "1 / span 5", // Adjust this to control row placement (e.g., across multiple rows if needed)
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <QRCodeSVG
                                value={`https://bhumeet.in/`}
                                height={window.innerWidth <= 1366 ? "120px" : "206px"}
                                width={window.innerWidth <= 1366 ? "120px" : "202px"}
                            />
                            <div
                                style={{
                                    cursor: "pointer",
                                    marginTop: "15px",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#3b3b3b",
                                }}
                                onClick={() => triggerDownloadQRCode(5120)}
                            >
                                Download QR Code
                            </div>
                        </div>
                    </div>

                    <div>

                    </div>

                    <br />
                    <div style={{ fontWeight: 600, margin: "10px 0px" }}>Owner Details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px",width:"83%" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{store.owner_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Email</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{store.email || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Mobile No</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div >{store.mobile_number || "-"}</div>
                        </div>

                    </div>
                    {store.bankdetails && (
                        <>
                            <div style={{ fontWeight: 600, margin: "10px 0px" }}>Bank Details</div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px",width:"83%" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Bank Name</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{store.bankdetails?.bank_name || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Holder </div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{store.bankdetails?.account_holder_name || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account No</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div >{store.bankdetails?.account_number || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>IFSC code</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div >{store.bankdetails?.ifsc_code || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Type</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div >{store.bankdetails?.account_type || "-"}</div>
                                </div>

                            </div>
                        </>
                    )}
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default ViewStoreDetails