import { buildUrl, getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";


export const getRequestsOverview = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        `${SERVER_URLS.BACKEND_SERVER}/dsp/overview/requests`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data);
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(data?.message || "Something went wrong while getting requests overview.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while getting requests overview.");
    }
  });
};

export const getRequestsCount = (userId, fromDate, toDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        `${SERVER_URLS.BACKEND_SERVER}/dsp/requests/getRequestsCount?userId=${userId || ""}${fromDate ? `&fromDate=${fromDate || " "}` : " "}${toDate ? `&toDate=${toDate || " "}` : " "}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data);
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(data?.message || "Something went wrong while getting requests.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while getting requests.");
    }
  });
};
export const getRequests = (userId, filter, pageSize, pageNumber, searchQuery, fromDate, toDate, pilotId, isOrderByServiceTime) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        `${SERVER_URLS.BACKEND_SERVER}/dsp/requests?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""
        }&searchQuery=${searchQuery || ""}&orderBy=${filter?.orderBy || ""}&orderType=${filter?.orderType || ""}&services=${filter?.services?.length > 0 ? encodeURIComponent(JSON.stringify(filter.services)) : ""}&statuses=${filter?.statuses?.length > 0 ? encodeURIComponent(JSON.stringify(filter?.statuses)) : ""}&locations=${filter?.locations?.length > 0 ? encodeURIComponent(JSON.stringify(filter?.locations)) : ""}${userId ? `&userId=${userId}` : ""}${fromDate ? `&fromDate=${fromDate || " "}` : " "}${toDate ? `&toDate=${toDate || " "}` : " "}${pilotId ? `&pilotId=${pilotId}` : ""}${isOrderByServiceTime ? `&isOrderByServiceTime=true` : ""}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        data = await response.json();
      }
      if (response.ok && response.status === 200) {
        resolve({ requests: data.requests });
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(data?.message || "Something went wrong while getting requests.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while getting requests.");
    }
  });
};

export const getRequest = (req, needDSPDetails) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/requests/${req.type}/${req.id}${needDSPDetails ? `?needDSPDetails=${needDSPDetails}` : ""}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data);
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(data?.message || "Something went wrong while getting requests.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while getting requests.");
    }
  });
};

export const updateRequest = (request, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/requests`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ request: { ...request, type } }),
        }
      );
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data);
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(data?.message || "Something went wrong while updating request.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while updating request.");
    }
  });
};

export const applyDiscount = ({ id, dsp_discount }) => {
  return new Promise(async (resolve, reject) => {
    resolve()
    // try {
    //   let data;
    //   const token = getToken();
    //   const response = await fetch(
    //     SERVER_URLS.BACKEND_SERVER + `/dsp/requests/applyDiscount`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //       body: JSON.stringify({
    //         id: id,
    //         dsp_discount: dsp_discount
    //       }),
    //     }
    //   );
    //   if (response.ok && response.status === 200) {
    //     data = await response.json();
    //     resolve(data);
    //   } else {
    //     reject(data?.message || "Something went wrong while updating request.");
    //   }
    // } catch (error) {
    //   console.log(error);
    //   reject("Something went wrong while updating request.");
    // }
  });
};



export const getRequestsCountPerDate = (fromDate, toDate, pilotId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/requests/dateCount/${encodeURIComponent(fromDate)}/${encodeURIComponent(toDate)}${pilotId ? `?pilotId=${pilotId}` : ""}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data);
      } else {
        reject(data?.message || "Something went wrong while getting requests count.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while getting requests count.");
    }
  });
};

export const getCrops = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/requests/getCrops`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data.crops[0]?.crop_types);
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(data?.message || "Something went wrong while getting requests.");
      }
    } catch (error) {
      console.log(error);
      reject("Something went wrong while getting requests.");
    }
  });
};


export const createRequestFromDSP = (request) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data;
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/requests`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(request)
        }
      );
      data = await response.json();
      if (response.ok && response.status == 201) {
        resolve(data);
      } else if (response.status === 204) {
        resolve({ requests: [] });
      } else {
        reject(response?.status == 400 ? data?.message : "Something went wrong while creating request");
      }
    } catch (error) {
      console.log(error);
      reject(error.message || "Something went wrong while creating request");
    }
  });
};

export const cancelRequest = async (requestId,note) => {
  try {
      const token = getToken();
      const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/customerSuccessEngineer/dsp/request/cancel`, {
        requestId:requestId?requestId:undefined,
        note:note?note:undefined
    });
      const response = await fetch(url,
          {
              method: "PUT",
              headers: {
                  Authorization: `Bearer ${token} `,
              },
          }
      );

      const res = await response.json();
      if (!response.ok) {
          throw (res.message || "Something went wrong while getting coupons");
      }
      return res;

  } catch (err) {
      throw (err || "Something went wrong while getting coupons");
  }
}

