import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import customersCardIcon from "../../assets/customersCardIcon.svg"
import teamsCardIcon from "../../assets/teamsCardIcon.svg"
import serviceCardIcon from "../../assets/serviceCardIcon.svg"
import { PDRL_FINANCE_ROUTES } from '../../routes'
import { getFinanaceDashboardOverview } from '../../apis/BhuMeetTeams/BhuMeetFinanace/dashboard'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import { addLeadingZero } from '../../ReusableFunctions/reusableFunctions'
import formatNumber from '../../ReusableFunctions/convertLongNumToShort'

const FinanceDashboard = () => {
    const [dashBoardData, setDashBoardData] = useState({
        totalRevenue: 0,
        totalBhumeetCouponsAmount: 0,
        totalTdsCharges: 0,
        totalPlatformFeesAmount: 0,
        billedToCustomer: 0
    })

    const getAdminDashboardOverviewFunc = async () => {
        Loading.set(true)
        try {
            const overview = await getFinanaceDashboardOverview()
            // console.log(overview)
            // overview.dashboardOveriewData['billedToCustomer'] = (dashBoardData?.billedToCustomer).toFixed() > -1 ? addLeadingZero((dashBoardData?.billedToCustomer).toFixed()) : (dashBoardData?.billedToCustomer).toFixed()
            for(let i in overview.dashboardOveriewData){
                overview.dashboardOveriewData[i] = formatNumber(overview.dashboardOveriewData[i])
            }
            setDashBoardData(overview?.dashboardOveriewData)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        getAdminDashboardOverviewFunc()
        localStorage.setItem('dsp_id', "admin")
        localStorage.removeItem("requestFilters")
    }, [])
    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    <DashBoardCard
                        loading={false}
                        title="Orders"
                        icon={customersCardIcon}
                        data={`₹ ${(dashBoardData?.billedToCustomer)}`}
                        view={PDRL_FINANCE_ROUTES.ORDERS}
                    />

                    <DashBoardCard
                        loading={false}
                        title="DSP Invoices"
                        data={dashBoardData?.totalDspInvoices}
                        icon={teamsCardIcon}
                        view={PDRL_FINANCE_ROUTES.DSP_INVOICES}
                        state={{ isDspInvoices: true }}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Customer Invoices"
                        data={dashBoardData?.totalUserInvoices}
                        icon={serviceCardIcon}
                        view={PDRL_FINANCE_ROUTES.USER_INVOICES}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Coupons"
                        data={`₹ ${dashBoardData?.totalBhumeetCouponsAmount}`}
                        icon={serviceCardIcon}
                    />

                    <DashBoardCard
                        loading={false}
                        title="TDS Charges"
                        data={`₹ ${dashBoardData?.totalTdsCharges}`}
                        icon={teamsCardIcon}
                        view={PDRL_FINANCE_ROUTES.DSP_INVOICES}
                        state={{ isFromTds: true }}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Platform Fees"
                        data={`₹ ${dashBoardData?.totalPlatformFeesAmount}`}
                        icon={serviceCardIcon}
                        view={PDRL_FINANCE_ROUTES.DSP_INVOICES}
                        state={{ isFromPlatformFee: true }}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Incentive Paid"
                        icon={customersCardIcon}
                        data={`₹ ${dashBoardData?.totalIncentiveFromBhumeet}`}
                        view={PDRL_FINANCE_ROUTES.DSP_INVOICES}
                        state={{ isFromIncentive: true }}
                    />

                    <DashBoardCard
                        loading={false}
                        view={PDRL_FINANCE_ROUTES.FINANCEDSPS}
                        title="DSPs"
                        data={dashBoardData?.dsps}
                        icon={customersCardIcon}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default FinanceDashboard