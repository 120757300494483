import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const updateCustRewardOrderDetails = async (rewardOrderDetails) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/customerSuccessEngineer/rewardOrders`,
            {
                method: "PUT",
                body: JSON.stringify(rewardOrderDetails),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to update order details.");
        }
        return res;
    } catch (err) {
        throw (err);
    }
}