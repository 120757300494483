import React, { useState } from 'react';
import '../Operations/DspDetails.css';
import { useLocation } from 'react-router';
import ViewFinanceDsp from './FinanceDspDetails';



function FinanceDspDetailsDashboard() {
    const [activeTab, setActiveTab] = useState('details');
    const location = useLocation();
    const [dsp, setDsp] = useState(location.state?.dsp)
    const tabs = ['details'];
    return (
        <div style={{ backgroundColor: "rgb(236, 239, 243)", overflow: "hidden" }} className='flex flex-col gap-5 h-[100%]  px-[20px] py-[20px]'>
            <div className="flex-grow overflow-y-auto">
                {activeTab === "details" ? (
                    <ViewFinanceDsp hideDspActiveButton hideBankDetails={true} dsp={dsp} callback={(dsp) => {
                        setDsp(dsp);
                    }} />
                ) : null}
            </div>
        </div>
    );
}

export default FinanceDspDetailsDashboard;