import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getRewardProducts = async ({pageNumber,pageSize}) => {
    try {
            const token = getToken();
            const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/operations/rewardProgram/products`, {
                pageSize: pageSize && pageNumber ? pageSize : undefined,
                pageNumber: pageSize && pageNumber ? pageNumber : undefined
            });
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token} `,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.products;
    } catch (err) {
        throw (err)
    }
}

export const createRewardProduct = async (productInfo) => {
    try {
            const token = getToken();
            const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/operations/rewardProgram/products`,
                {
                    method: "POST",
                    body: productInfo,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.product;
    } catch (err) {
        throw (err)
    }
}

export const updateRewardProduct = async (id,productInfo) => {
    try {
            const token = getToken();
            const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/operations/rewardProgram/products/${id}`,
                {
                    method: "PUT",
                    body: JSON.stringify(productInfo),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res?.product;
    } catch (err) {
        throw (err)
    }
}


export const deleteRewardProduct = async (id) => {
    try {
            const token = getToken();
            const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/operations/rewardProgram/products/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            const res = await response.json();
            if (!response.ok) {
                throw (res.message || "Something went wrong while getting coupons");
            }
            return res;
    } catch (err) {
        throw (err)
    }
}

export const getAllProductsCount = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/operations/rewardProgram/products/count`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting orders count.");
        }
        return res;
    } catch (err) {
        throw (err)
    }
}