const formatNumber = (num) => {
    num = parseInt(num)
    if (num >= 1000) {
        return num.toLocaleString('en-IN');
    } else {
        return num.toString();
    }
};

export default formatNumber;
