import { useEffect, useRef, useState } from "react";
import Map from "../../features/LeafletMap/Map";
import Popup from "../../ReusableFunctions/Popup";
import MarkerIcon from "../../assets/marker.png";
import SelectedMarkerIcon from "../../assets/selectedMarker.png";
import L from "leaflet";
import roadMapView from "../../assets/roadMapView.png";
import sateliteView from "../../assets/sateliteView.png";
import { GLOBAL_STYLES, MAP_URLS } from "../../appConstants";
import generateRandomColor from "../../utils/generateRandomColor";
import { getUserListForMap } from "../../apis/users";
import "../../AdminComponents/Locations/Location.css";
import Loading from "../../ReusableFunctions/Loader";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import fullScreenIcon from "../../assets/fullScreenIcon.png";
import collapseIcon from "../../assets/collapse.png";

let baseLayer;
const ViewUsersOnLocation = ({ searchString, filters, setUsersonMapCount }) => {
  const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer);
  const [users, setUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [center, setCenter] = useState({ lat: 19.993982, lng: 73.790416 });
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const markerIcon = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [24, 32],
  });
  const initMap = (map) => {
    setMap(map);
  };

  const switchBaseLayer = () => {
    const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite";
    setActiveBaseLayer(activeLayer);
    if (baseLayer) {
      baseLayer.setUrl(
        activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP
      );
    }
  };
  const clearMap = () => {
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker || layer instanceof L.MarkerClusterGroup) {
        map.removeLayer(layer);
      }
    });
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
        mapContainerRef.current.requestFullscreen().catch(err => {
            console.error(`Error attempting to enable full-screen mode: ${err.message}`);
        });
    } else {
        document.exitFullscreen();
    }
}

  const initData = async () => {
    Loading.set(true);
    try {
      const res = await getUserListForMap(
        searchString,
        filters?.fromDate,
        filters?.toDate
      );
      setUsers(res?.allUsers);
      setUserCount(res?.count);
      setUsersonMapCount(res?.count);
      clearMap();
      if (res?.allUsers?.length > 0) {
        drawUsersOnMap(res?.allUsers);
      } else {
        setUsers([]);
        setUsersonMapCount(0);
      }
      Loading.set(false);
    } catch (e) {
      Loading.set(false);
      console.log(e);
      Popup.alert(
        "ERROR",
        e?.message || "Something went wrong while displaying information",
        "ERROR"
      );
    }
  };

  const drawUsersOnMap = (users) => {
    var markerOptions = {
      clickable: true,
      draggable: false,
      icon: markerIcon,
    };

    let clusterInactive = new L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount();
        return L.divIcon({
          html:
            '<div style="background-color:red; color:white;"><span>' +
            count +
            "</span></div>",
          className: "marker-cluster marker-cluster-medium active_cluster",
          iconSize: L.point(45, 45),
        });
      },
    });
    let clusterActive = new L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount();
        return L.divIcon({
          html:
            '<div style="background-color:green; color:white;"><span>' +
            count +
            "</span></div>",
          className: "marker-cluster marker-cluster-medium active_cluster ",
          iconSize: L.point(45, 45),
        });
      },
    });

    const newUsers = users.map((u) => {
      const center = { lat: u.lat, lng: u.lng };

      let marker1 = new L.Marker(center, markerOptions);
      marker1.on("click", function () {
        const currentZoom = Math.min(map.getZoom() + 1, map.getMaxZoom());
        map.flyTo(center, currentZoom);
      });
      const tooltipContent = `
                <div style="display:flex; height:80px; min-width:200px; ">
                    <div style="padding:10px;">
                        <div>User name: <span style="font-weight:600;">${u.name} </span></div>
                        <div>lat: <span style="font-weight:600;">${u.lat} </span></div>
                        <div>lng: <span style="font-weight:600;">${u.lng} </span></div>
                    </div>
                </div>
                `;
      marker1.bindTooltip(tooltipContent, {
        className: "location-tooltip",
        permanent: false,
      });
      if (u.is_active) {
        clusterActive.addLayer(marker1);
      } else {
        clusterInactive.addLayer(marker1);
      }
      return { ...u, markerLeafletLayer: marker1 };
    });
    setUsers(newUsers);
    map?.addLayer(clusterInactive);
    map?.addLayer(clusterActive);
  };

  useEffect(() => {
    if (map && (filters || searchString)) {
      initData();
    }
  }, [map, filters, searchString]);

  return (
    <div
    ref={mapContainerRef}
      style={{
        width: "100%",
        height: "100%",
        minHeight:"350px",
        position: "relative",
        zIndex: 50,
      }}
    >
      <Map
        setBaseLayerToState={(bL) => {
          baseLayer = bL;
        }}
        initZoom={6}
        handleMapClick={() => {}}
        maxZoom={23}
        style={{
          height: "100%",
          borderRadius: "10px",
          boxShadow: '0px 0px 8.9px 3px rgba(114, 114, 114, 0.15)',
        }}
        mapInitialized={(map) => {
          initMap(map);
        }}
      />
      <div
        style={{
          border: "solid 1px rgb(102, 102, 102, 0.3)",
          height: "25px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: "999",
          position: "absolute",
          right: "10px",
          bottom: "24px",
        }}
        onClick={switchBaseLayer}
      >
        <img
          style={{ width: "80px", height: "25px", borderRadius: "4px" }}
          src={activeBaseLayer === "satelite" ? roadMapView : sateliteView}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: -10,
          left: "15px",
          height: "50px",
          zIndex: "999",
          cursor: "pointer",
        }}
      >
        <img
          src={isFullScreen ? collapseIcon : fullScreenIcon}
          onClick={toggleFullScreen}
          alt=""
          width={isFullScreen ? "25px" : "20px"}
          height={"25px"}
        />
      </div>
    </div>
  );
};
export default ViewUsersOnLocation;
