import React, { useEffect, useState } from 'react'
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from '../../routes'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { getOrdersCount } from '../../apis/BhuMeetTeams/Oparations/RewardOrders'
import Popup from '../../ReusableFunctions/Popup'
import { getStoresCount } from '../../apis/BhuMeetTeams/Oparations/store'
import { useLocation } from 'react-router-dom'
import { getAllOrdersCount } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/dashboard'

export default function RewardProgram() {
    const [ordersCount, setOrdersCount] = useState(0)
    const location = useLocation();
  
    useEffect(() => {
        getOrdersCountFunc();
    }, [])


    const getOrdersCountFunc = async () => {
        try {
              let ordersCount=await getAllOrdersCount();
            setOrdersCount(ordersCount);
        } catch (err) {
            Popup.alert("ERROR", "We cant get orders count now, Please try again later.")
        }
    }

    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    <DashBoardCard
                        loading={false}
                        view={PDRL_SUCCESS_ENGI_ROUTES.success_REWARDORDERS}
                        title="Orders"
                        data={ordersCount}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}
