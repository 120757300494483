import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { PDRL_ADMIN_ROUTES, PDRL_SUCCESS_ENGI_ROUTES } from '../../routes'
import { addLeadingZero } from '../../ReusableFunctions/reusableFunctions'
import customersCardIcon from "../../assets/customersCardIcon.svg"
import teamsCardIcon from "../../assets/teamsCardIcon.svg"
import serviceCardIcon from "../../assets/serviceCardIcon.svg"
import Loading from '../../ReusableFunctions/Loader'
import Popup from '../../ReusableFunctions/Popup'
import { getCustSuccDashboardOverview } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/dashboard'
import { getStoresCount } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/dashboard'

import { useLocation } from 'react-router-dom'
import formatNumber from '../../ReusableFunctions/convertLongNumToShort'

const CustSuccessDashboard = () => {
    const [dashBoardData, setDashBoardData] = useState({})
    const [StoreCount,setStoreCount] = useState(0)
    const [location,setLocation] = useState('')
    

    const Location = useLocation()

    const getAdminDashboardOverviewFunc = async () => {
        Loading.set(true)
        try {
            const overview = await getCustSuccDashboardOverview()
            console.log('overview:', overview)
            setDashBoardData(overview?.dashboardOverview)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const getStoresCountFunc = async () => {
        try {
            let storesCount = await getStoresCount();
            setStoreCount(storesCount);
        } catch (err) {
            Popup.alert("ERROR", "We cant get stores count now, Please try again later.")
        }
    }



    useEffect(() => {
        getAdminDashboardOverviewFunc()
        localStorage.setItem('dsp_id', "admin")
        getStoresCountFunc()
        localStorage.removeItem("requestFilters");
        setLocation(Location.pathname)
    }, [])

    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    {/* ---- DSPs ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.DRONE_SERVICE_PROVIDERS}
                        title="DSPs"
                        data={addLeadingZero(dashBoardData?.dsps)}
                        icon={customersCardIcon}
                    />

                    {/* ---- Users ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.USERS}
                        title="Users"
                        data={addLeadingZero(dashBoardData?.users)}
                        icon={teamsCardIcon}
                    />

                    {/* ---- Requests ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.REQUESTS}
                        title="Requests"
                        data={formatNumber(Number(dashBoardData?.totalRequest))}
                        icon={serviceCardIcon}
                    />
                    <DashBoardCard
                        loading={false}
                        view={PDRL_SUCCESS_ENGI_ROUTES.CustStore}
                        title="Store"
                        data={StoreCount}
                        state={location}
                        
                        // icon={serviceCardIcon}
                    />
                    <DashBoardCard
                        loading={false}
                        view={PDRL_SUCCESS_ENGI_ROUTES.success_REWARDPROGRAM}
                        title="Reward program"
                        state={location}
                        // data={addLeadingZero(dashBoardData?.totalRequest)}
                        // icon={serviceCardIcon}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default CustSuccessDashboard