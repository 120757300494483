import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Components/Helper";
import { Link } from "react-router-dom";
import { isUserLoggedIn, signUp } from "../../apis/authentication";
import MailIcon from "../../assets/mail icon.svg";
import LeftSVG from "../../assets/keft.svg";
import RightSVG from "../../assets/right.svg";
import BhumitLogo from "../../assets/bhumeet.svg";
import Identification from "../../assets/IdentificationBadge.svg";
import Locked from "../../assets/locked.svg";
import LockCheck from "../../assets/lock check.svg";
import CompanyName from "../../assets/Buildings.svg";
import { GLOBAL_STYLES } from "../../appConstants";
import Popup from "../../ReusableFunctions/Popup";
import Form from "../../ReusableComponents/Form";
import InputField from "../../ReusableComponents/InputField";
import {  companyName, confirmPasswordValidation, emailValidation, firstLastNameValidation, newPasswordValidation } from "../../utils/inputValidations";
const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = (user) => {
    setLoading(true);
    signUp(user)
      .then((res) => {
        setLoading(false);
        Popup.alert(
          "Account created successfully",
          "We will notify you once your account has been activated.",
          "SUCCESS",
          () => {
            navigate("/login");
          }
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  useEffect(() => {
    document.title = "BhuMeet - Signup";
  }, []);

  return isUserLoggedIn() ? (
    <Navigate to="/" />
  ) : (
    <div style={{ width: "100%", height: "100%", background: "" }}>
      <div style={{ position: "absolute", left: "15%", width: "180px", top: "5%" }}>
        <img src={BhumitLogo} alt="BhuMeet logo" style={{ width: "100%" }} />
      </div>
      <div
        style={{
          position: "absolute",
          left: "0px",
          width: "220px",
          bottom: "0",
        }}
      >
        <img src={LeftSVG} alt="Desing-left" style={{ width: "100%" }} />
      </div>
      <div style={{ position: "absolute", right: "0px", width: "220px", top: "0" }}>
        <img src={RightSVG} alt="Design-right" style={{ width: "100%" }} />
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          left: "50%",
          height: "550px",
          width: "490px",
          display: "flex",
          flexDirection: "column",
          padding: "39px 61px 40px",
          border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
          borderRadius: '5px',
          boxShadow: "0px 1px 4px 2px #76767626",
          background: "white",
        }}
      >
        <div style={{ marginBottom: "30px" }}>
          <div
            style={{
              fontSize: "22px",
              fontWeight: "700",
              color: "#252525",
              marginBottom: "8px",
              letterSpacing: "0.4px",
            }}
          >
            Sign up
          </div>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              textAlign: "left",
              color: "#727272",
            }}
          >
            Please sign up with your details
          </div>
        </div>
        <Form
          onSubmitForm={(user) => {
            handleSignUp(user)
          }}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(onSubmit) =>
            <>
              <div style={{ display: "flex", width: "100%", columnGap: "20px" }}>
                <div style={{ minHeight: "70px", width: "50%" }}>
                  <InputField
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="First Name"
                    icon={Identification}
                    style={{
                      height: '35px',
                      fontSize: "15px",
                      fontWeight: 400,
                      letterSpacing: "0.3px",
                    }}
                    validation={firstLastNameValidation}
                  />
                </div>
                <div style={{ minHeight: "70px", width: "50%" }}>
                  <InputField
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Last Name"
                    icon={Identification}
                    style={{
                      height: '35px',
                      fontSize: "15px",
                      fontWeight: 400,
                      letterSpacing: "0.3px",
                    }}
                    validation={firstLastNameValidation}
                  />
                </div>
              </div>
              <div style={{ minHeight: "65px" }}>
                <InputField
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  icon={MailIcon}
                  style={{
                    height: '35px',
                    fontSize: "15px",
                    fontWeight: 400,
                    letterSpacing: "0.3px",
                  }}
                  validation={emailValidation}
                />
              </div>
              <div style={{ minHeight: "65px" }}>
                <InputField
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Company name"
                  icon={CompanyName}
                  style={{
                    height: '35px',
                    fontSize: "15px",
                    fontWeight: 400,
                    letterSpacing: "0.3px",
                  }}
                  validation={companyName}
                />
              </div>
              <div style={{ minHeight: "65px" }}>
                <InputField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="password"
                  required
                  icon={Locked}
                  onClickIcon={() => setShowPassword(!showPassword)}
                  style={{
                    height: '35px',
                    fontSize: "16px",
                    fontWeight: 400,
                    letterSpacing: "0.3px",
                  }}
                  validation={newPasswordValidation}
                />
              </div>
              <div style={{ minHeight: "65px" }}>
                <InputField
                  type={showConfirmPassword ? "text" : "password"}
                  id="verify-password"
                  name="verifyPassword"
                  placeholder="Confirm Password"
                  icon={LockCheck}
                  onClickIcon={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    height: '35px',
                    fontSize: "16px",
                    fontWeight: 400,
                    letterSpacing: "0.3px",
                  }}
                  validation={confirmPasswordValidation}
                />
              </div>
              <Button
                text={loading ? "Signing up ..." : "Sign up"}
                isDisabled={loading}
                style={{
                  width: "100%",
                  borderRadius: "2px",
                  border: "1px",
                  color: "white",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "17px 0px",
                  marginTop: "3px",
                }}
                onClick={() => onSubmit}
              />
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontSize: '13px',
                    fontWeight: 600,
                    color: 'rgb(59, 59, 59)',
                    marginTop: '-2px'
                  }}
                >
                  Already have an account ?{" "}
                  <Link to={"/login"} style={{ color: GLOBAL_STYLES.BG_ACTIVE }}>
                    Log in
                  </Link>
                </span>
              </div>
            </>
          }
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
