import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { GLOBAL_STYLES } from "../appConstants";
import { CrossCloseButton } from "../Components/Helper";
import { capitalizeFirstLetter } from "../ReusableFunctions/reusableFunctions";
const defaultContentStyle = {
  width: "32em",
  height: "16em",
  borderRadius: "5px",
  maxWidth: "100%",
  padding: "0px",
  boxShadow: "0px 1px 4px 6px #76767626",
};
const defaultOverlayStyle = {
  zIndex: "999",
  background: "#1E1E1E33",
  backdropFilter: "blur(5px)",
  overflow: "auto"
};
const defaultArrowStyle = {};
const Modal = (props) => {
  return (
    <Popup
      offsetX={props.offsetX || 0}
      offsetY={props.offsetY || 0}
      open={props.open}
      modal
      onClose={props.onClose}
      position={props.position || "center"}
      {...{
        modal: true,
        closeOnDocumentClick:
          props.closeOnDocumentClick === undefined
            ? false
            : props.closeOnDocumentClick,
        contentStyle: { ...defaultContentStyle, ...props.contentStyle },
        overlayStyle: { ...defaultOverlayStyle, ...props.overlayStyle },
        arrowStyle: { ...defaultArrowStyle, ...props.arrowStyle },
      }}
    >
      {props.header && <div
        style={{
          padding: "25px 30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #bfc8d980",
          ...props.headerContainerStyle
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#1E1E1E",
            fontFamily: "Manrope",
            ...props.headingStyle,
          }}
        >
          {capitalizeFirstLetter(props.header)}
        </div>
        <CrossCloseButton onClick={props.onClose} width={props.iconSize} />
      </div>}
      {props.divider && <hr style={{ color: "#BFC8D9" }} />}
      {props.children}
    </Popup >
  );
};

export default Modal;
