import React, { useState } from "react";
import ReactLoading from "react-loading";
import { GLOBAL_STYLES, STATUS } from "../../../../appConstants";
import { reduceLongName } from "../../../../ReusableFunctions/reusableFunctions";
import Paid from "../../../../assets/paid.svg"
import InProgress from "../../../../assets/inprogress.svg"
import Received from "../../../../assets/recieved.svg"
import accepted from "../../../../assets/accepted.svg"
import cancle from "../../../../assets/cancle.svg"
import outForService from "../../../../assets/outForService.svg"
import reschedule from "../../../../assets/reschedule.svg"
import Completed from "../../../../assets/completed.svg"
import OnHold from "../../../../assets/OnHold.svg"
import Tippy from "@tippyjs/react";
import useWindowSize from "../../../../hooks/useWindowSize";


const AdminStatusCountCard = ({ backGround, loading, title, data, icon, isDisabled, iconStyle, callback, isActive }) => {
    const [imgIcon, setImgIcon] = useState(icon)
    const{currentWidth}=useWindowSize();
    const pickIcon = (status) => {
        switch (status) {
            case STATUS.ACCEPTED:
                return accepted;

            case STATUS.OUT_FOR_SERVICE:
                return outForService;

            case STATUS.COMPLETED:
                return Completed;

            case STATUS.INPROGRESS:
                return InProgress;

            case STATUS.RESCHEDULED:
                return reschedule;

            case STATUS.PAID:
                return Paid;

            case STATUS.PLACED:
                return Received;

            case STATUS.CANCELLED:
                return cancle;
                
            case STATUS.HOLD:
                return OnHold;

            case "Total Orders":
                    return Received;
        }
    }
    return (
        <div className="orders-card-parent"
            onClick={(e) => {

            }}
            onMouseOver={() => setImgIcon(icon)}
            onMouseOut={() => setImgIcon(icon)}
            style={{ cursor: isDisabled ? "not-allowed" : "pointer", pointerEvents: isDisabled || !callback ? "none" : undefined, background: !isActive ? backGround : "lightgray", color: backGround ? "white" : undefined }}
        >
            {
                loading ?
                    <div style={{ position: "relative", height: "100%", width: "100%" }}>
                        <ReactLoading
                            className="loading"
                            type={"spin"}
                            color={GLOBAL_STYLES.BG_ACTIVE}
                            height={"13%"}
                            width={"10%"}
                        />
                    </div> : 
                    <div onClick={()=>{
                        callback(data?.order_status)
                    }} style={{ display: "flex", flexDirection:"column", width: "100%" }} >
                        <div className="orders_card_parent-header">
                         <div style={{display:"flex",width:"100%",alignItems:"center",gap:currentWidth<=1366?"10px":"20px"}}>
                            <div style={{ color: backGround ? "white" : undefined,}} className="dashboard-card-parent-inner1 dashboard-cards-heading"  >{data?.order_status}</div>
                            <div className="dashboard-card-parent-inner2 dashboard-cards-main-data" style={{ fontSize: "20px" }}> {data?.total_orders}</div>
                            
                        </div>
                        {imgIcon && <div className="orders-card-parent-inner3-container">
                            <img className="orders-card-parent-inner3" style={{ ...iconStyle,width:currentWidth<=1366?"90%":"100%" }} src={pickIcon(data?.order_status)} />
                          </div>}
                        </div>
                        <div style={{}}>
                         <Tippy content={data?.total_amount}>
                                <div className="dashboard-card-parent-inner2 dashboard-cards-main-data" style={{ fontSize: "20px" }}> {Number(data?.total_amount) ? `₹ ${reduceLongName(Number(data?.total_amount).toFixed(), currentWidth>1367?15:8)} ` || '' : data?.total_amount}</div>
                            </Tippy>
                        </div>
                    </div>
                    // reduceLongName(Number(data?.total_amount).toFixed()

            }
        </div>
    );
};

export default AdminStatusCountCard;
