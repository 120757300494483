import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Loading from '../../ReusableFunctions/Loader';
import { deleteAdminCoupon, getAllAdminCoupons, getAllAdminCouponsCount, getAllCoupons,getAllAdminCouponsStat } from '../../apis/coupons';
import MainContentWrapper from '../../Components/MainContentWrapper';
import TableView from '../../ReusableComponents/TableView/TableView';
import NotFounds from '../../ReusableComponents/NotFounds';
import Plus from "../../assets/Plus.svg";
import { Button } from '../../Components/Helper';
import AddAdminCouponForm from './AddAdminCouponForm';
import search from "../../assets/search.svg"
import Popup from '../../ReusableFunctions/Popup';
import _debounce from 'lodash/debounce';
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard';
const PAGE_SIZE = 15

const InvoicesStatCard = ({ title, statNumber, isSelected, onClick, isDisabled }) => {
    return <DashBoardCard
        isDisabled={isDisabled}
        title={title}
        data={statNumber}
        onClick={onClick}
        backGround={isSelected ? "green" : undefined}
    />
}

const InvoiceStats = ({ invoicesStats, filters, changeStatusFilter }) => {
    return <div style={{ display: "flex", columnGap: "2%",marginBottom:"2px"}}>

        <InvoicesStatCard
            title={`All coupons`}
            statNumber={Number(invoicesStats.total_count)}
            isSelected={filters.QueryType === '*'}
            onClick={() => {changeStatusFilter({Query:"*",count:invoicesStats.total_count})}}
        />

        <InvoicesStatCard
            title={`Used coupons (₹ ${Number(invoicesStats.total_consumed_amount).toFixed()}) `}
            statNumber={Number(invoicesStats.redeem_count_1).toFixed()}
            isSelected={filters.QueryType === 'Used_coupons'}
            onClick={() => {changeStatusFilter({Query:"Used_coupons",count:invoicesStats.redeem_count_1})}}
        />
        

        <InvoicesStatCard
            title={`Unused Coupons`}
            statNumber={Number(invoicesStats.redeem_count_0).toFixed()}
            isSelected={filters.QueryType === 'Unused_coupons'}
            onClick={() => {changeStatusFilter({Query:"Unused_coupons",count:invoicesStats.redeem_count_0})}}
        />

<InvoicesStatCard
            title={`Active Coupons`}
            statNumber={Number(invoicesStats.live_coupon_status_count).toFixed()}
            isSelected={filters.QueryType === 'LIVE'}
            onClick={() => {changeStatusFilter({Query:"LIVE",count:invoicesStats.live_coupon_status_count})}}
        />
        <InvoicesStatCard
            title={`Inactive Coupons`}
            statNumber={Number(invoicesStats.exp_coupon_status_count).toFixed()}
            isSelected={filters.QueryType === 'EXP_Coupon'}
            onClick={() => {changeStatusFilter({Query:"EXP_Coupon",count:invoicesStats.exp_coupon_status_count})}}
        />
        <br/>
    </div>
}

export default function AdminCoupons() {
    const [currentPage, setCurrentPage] = useState(1)
    const [coupons, setCoupons] = useState([]);
    const [couponsStat, setCouponsstat] = useState([]);
    const [couponsCount, setCouponsCount] = useState(0)
    const [loading, setLoading] = useState(false);
    const [openCouponPopup, setOpenCouponPopup] = useState(false);
    const [couponId, setCouponId] = useState(null)
    const [searchString, setSearchString] = useState("")
    let srNo = (currentPage - 1) * PAGE_SIZE
    const [filters, setFilters] = useState({
        QueryType: "*",
        searchString: undefined,
        fromDate: undefined,
        toDate: undefined,
        paymentMode: undefined,
        count : 0
    })


    const onEdit = (coupon) => {
        setCouponId(coupon.id)
        setOpenCouponPopup(true)
    }


    const deleteCoupon = async (coupon) => {
        Loading.set(true)
        try {
            await deleteAdminCoupon(coupon.id)
            setCoupons(prev => prev.filter(c => c.id !== coupon.id))
            Popup.alert("Coupon deleted", "Coupon deleted successfully", "SUCCESS")
            Loading.set(false)
        } catch (e) {
            Loading.set(false)
            console.log(e)
            Popup.alert("ERROR", "Something went wrong while deleting coupon", "ERROR")
        }
    }
    const initData = _debounce(async () => {
        await getCoupons();
        await getCouponsCount()
        let getvalues = await getCouponsStats()
        // console.log(getvalues)

        switch (true) {
            case filters.QueryType === 'LIVE':
                setFilters({ ...filters,count:getvalues.live_coupon_status_count})
                setCouponsCount(Number(couponsStat.live_coupon_status_count))
                break;
            case filters.QueryType === '*':
                setFilters({ ...filters,count:getvalues.total_count})
                break
            case filters.QueryType === 'Unused_coupons':
                setFilters({ ...filters,count:getvalues.redeem_count_0})
                break
            case filters.QueryType === 'EXP_Coupon':
                setFilters({ ...filters,count:getvalues.exp_coupon_status_count})
                break
            case filters.QueryType === 'Used_coupons':
                setFilters({ ...filters,count:getvalues.redeem_count_1})
                break
            default:
                
                break;
        }
            

    }, 500)
    useEffect(() => {
        setLoading(true)
        initData()
    }, [searchString])

    useEffect(() => {
        // console.log(filters)
    }, [filters.count])


    async function getCouponsCount() {
        try {
            const res = await getAllAdminCouponsCount(searchString)
            setCouponsCount(Number(res.coupons_count))
            Loading.set(false);
            setLoading(false)
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    async function getCoupons() {
        try {
            const res = await getAllAdminCoupons(PAGE_SIZE, currentPage, searchString,filters.QueryType);
            setCoupons(res.coupons);

            
            
            // setFilters({count:res.coupons.length})
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }

    async function getCouponsStats() {
        try {
            const res = await getAllAdminCouponsStat(PAGE_SIZE, currentPage, searchString);
            setCouponsstat(res.coupons);
            // console.log(filters)

            
            // console.log(filters)
            // console.log(couponsCount)


            return res.coupons
            
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    useEffect(() => {
        if (currentPage) {
            getCoupons()
            getCouponsStats()
        }
    }, [currentPage])


    
    useEffect( () => {
        // console.log(filters)
        setCurrentPage(1)
        getCoupons()
        
            
    }, [filters.QueryType])
    return (
        
        
        <MainContentWrapper containerStyle={{overflow:"hidden"}} header={`Manage Coupons`}
            rightContent={coupons && coupons.length > 0 && <Button style={{padding : "15px 12px"}} type="submit" icon={Plus} text={"Add New"} onClick={() => {
                setOpenCouponPopup(true)
            }} />}>
            {(couponId || openCouponPopup) &&
                <AddAdminCouponForm
                    onRequestClose={() => {
                        setOpenCouponPopup(false)
                        setCouponId(null)
                        getCoupons()
                    }}
                    coupons={coupons}
                    couponId={couponId}
                />
            }
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                position: 'relative',
                gap:"20px",
                height:"100%"
            }}>
        
                <div style={{ position: "absolute", height: "40px", width: "360px", top: '-52px', left: '270px' }}>
                    <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
                    <input
                        onChange={(e) => {
                            setSearchString(e.target.value)
                            setCurrentPage(1)
                        }}
                        placeholder='Search coupons by name,code,status...'
                        style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
                </div>

                <InvoiceStats
                    invoicesStats={couponsStat}
                    filters={filters}
                    changeStatusFilter={(statusFiler) =>{ 
                         setFilters({ ...filters, QueryType: statusFiler.Query,count:statusFiler.count})}}
          
                
        />

                {coupons && coupons.length > 0 ?
                    <>
                        <TableView
                            onStateChange={async (changedState) => {
                                const { pageNumber } = changedState;
                                // console.log(filters)
                                setCurrentPage(() => pageNumber);
                                // getDspcouponsByPage(pageNumber);
                            }}
                            isLoading={loading}
                            tableContainerHeight={"75%"}
                            loaderHeight={'500px'}
                            columns={[
                                { Header: "Coupon Code", accessor: "coupon_code", },
                                { Header: "Discount Cost", accessor: "discount_cost", },
                                { Header: "Discount (%)", accessor: "discount_percentage" },
                                { Header: "Max Discount (Rs.)", accessor: "max_discount_value" },
                                { Header: "Usage Per User", accessor: "number_of_use_per_user" },
                                { Header: "Max Redemption Count ", accessor: "max_redemption_count" },
                                { Header: "Redeem Count", accessor: "redeemed_count" },
                                { Header: "Consumed Amount", accessor: "consumed_amount" },
                                { Header: "Min Order Price (Rs.)", accessor: "min_order_value" },
                                { Header: "Coupon Status", accessor: "coupon_status" },
                            ]}
                            data={coupons}
                            pageNumber={currentPage}
                            pageSize={PAGE_SIZE}
                            count={filters.count}

                            activities={{ onClickDelete: (coupon) => Popup.alert("WARNING", "Are you sure , you want to delete this coupon?", "WARNING", () => deleteCoupon(coupon)), onClickEdit: onEdit, canEdit: true }}

                        />
                    </>
                    :
                    coupons && coupons.length === 0 && (
                        <NotFounds msg={"OOPS, No Coupons found  !"} onBtnClick={() => {
                            // setOpenTeamsPopup(true)
                            setOpenCouponPopup(true)
                        }} />
                    )
                }
            </div>
        </MainContentWrapper>
    )
}
