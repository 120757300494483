import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getDronePilots = (dspId, pageSize, pageNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/customerSuccessEngineer/pilots", {
                pageNumber: pageNumber ? pageNumber : undefined,
                pageSize: pageSize ? pageSize : undefined,
                dspId: dspId ? dspId : undefined
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data?.pilots);
            } else {
                reject(
                    data?.message || "Something went wrong while getting drones."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting drones.");
        }
    });
};
