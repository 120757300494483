import React, { useState } from "react";
import Modal from "../../ReusableComponents/Modal";
import Input from "../../ReusableComponents/Input";
import { LIMITS } from "../../appConstants";
import { Button } from "../../Components/Helper";
import Toggle from "react-toggle";
import Popup from "../../ReusableFunctions/Popup";
import { createRewardProduct, updateRewardProduct } from "../../apis/BhuMeetTeams/Oparations/RewardProducts";
import Loading from "../../ReusableFunctions/Loader";
import { validateName } from "../../ReusableFunctions/reusableFunctions";

export default function AddProductModal({
  open,
  onClose,
  selectedProduct,
  onProductAdd,
}) {
  const [loading, setLoading] = useState(false)
  const [productInfo, setProductInfo] = useState(selectedProduct?.id?selectedProduct:{
    name: "",
    points: "",
    product_image: null,
    is_available: true,
    imagePreview: null,
  });
  const [error, setError] = useState({
    name: '',
    points: '',
});

const checkIsValid = (name, value) => {
  setError((prevErrors) => ({ ...prevErrors, [name]: '' }));

  switch (name) {
    case 'name':
      if (!value.trim()) {
        setError((prevErrors) => ({
          ...prevErrors,
          name: 'product name cannot be empty.',
        }));
        return false;
      }
      if (!/^[a-zA-Z0-9]+(?:[ _-][a-zA-Z0-9]+)*$/.test(value)) {
        setError((prevErrors) => ({
          ...prevErrors,
          name: 'Name should contain only letters, numbers and no whitespace',
        }));
        return false;
      }
      break;

    case 'points':
      if (!value.trim()) {
        setError((prevErrors) => ({
          ...prevErrors,
          points: 'poinst can not empty.',
        }));
        return false;
      }
      if (!/^\d+$/.test(value)) {
        setError((prevErrors) => ({
          ...prevErrors,
          points: 'Points must be a numeric value.',
        }));
        return false;
      }
      break;
    default:
      setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
      break;
  }
  return true;
};

  const onchangeProductInfo = (e) => {
    const { name, value, files } = e.target;
    checkIsValid(name, value)
    if (name === "product_image") {
      const file = files[0];
      setProductInfo({
        ...productInfo,
        product_image: file,
        imagePreview: file ? URL.createObjectURL(file) : null, 
      });
    } else {
      setProductInfo({ ...productInfo, [name]: value });
    }
  };

  const createProduct = async() => {
    try{
      const formData = new FormData();
      formData.append("name", productInfo.name);
      formData.append("points", productInfo.points);
      formData.append("is_available", productInfo.is_available);
      if (productInfo.product_image) {
        formData.append("product_image", productInfo.product_image); 
      }
      Loading.set(true);
      let AddedProduct=await createRewardProduct(formData);
      Loading.set(false)
      onProductAdd(AddedProduct)
     Popup.alert("Product", "Reward product added successfully!", 'SUCCESS')
    }catch(err){
      Loading.set(false)
      Popup.alert("Error", err, 'ERROR')
    }  
  };

  const handleUpdateProduct = async(id,productInfo) => {
    try{
      Loading.set(true);
      const product = await updateRewardProduct(id,productInfo);
      Popup.alert("Product", "Reward product updated successfully!", 'SUCCESS')
      onProductAdd(product);
      Loading.set(false);
    }catch(err){
        Loading.set(false);
      Popup.alert("Error", err, 'ERROR')
    }
  };

  return (
    <Modal
      header={selectedProduct ? "Update Product" : "Add Product"}
      open={open}
      onClose={onClose}
      contentStyle={{ width: "575px", height: "auto" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "11px",
          padding: "20px 30px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            rowGap: "15px",
          }}
        >
          {/* Product Name */}
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>
              Product Name
            </div>
            <Input
              onChange={onchangeProductInfo}
              name="name"
              placeholder="Name"
              value={productInfo.name}
              disabled={selectedProduct?.id}
              required={true}
              style={{ fontSize: "13px", height: "35px" }}
              maxLength={LIMITS.NAME}
            />
            {error.name && <div style={{fontSize:"11px",color:"red"}}>{error.name}</div>}
          </div>

          {/* Points */}
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Points</div>
            <Input
              onChange={onchangeProductInfo}
              name="points"
              placeholder="Product Points"
              value={productInfo.points}
              required={true}
              style={{ fontSize: "13px", height: "35px" }}
              maxLength={LIMITS.POINTS}
            />
             {error.points && <div style={{fontSize:"11px",color:"red"}}>{error.points}</div>}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Available</div>
                            <Toggle
                                className='admin'
                                checked={productInfo?.is_available }
                                icons={false}
                                onChange={(e) => {
                                   setProductInfo(()=>{
                                     return {
                                      ...productInfo,
                                       is_available: e.target.checked
                                     }
                                   })
                                }}
                            />
                        </div>

          {/* Image Upload */}
          {productInfo?.id 
          ?
          (<div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Product Image</div>
              <img
                src={productInfo.image_url}
                alt="Preview"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "contain",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                }}
              />
          </div>)
          :
          (<div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Product Image</div>
            <input
              type="file"
              name="product_image"
              accept={"image/jpeg, image/jpg, image/png"}
              onChange={onchangeProductInfo}
              style={{ fontSize: "13px", height: "25px" }}
            />
            {productInfo.imagePreview && (
              <img
                src={productInfo.imagePreview}
                alt="Preview"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                  objectFit: "contain",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                }}
              />
            )}
          </div>
          )
          }
         {(!productInfo.product_image && !selectedProduct?.id) &&  <div style={{fontSize: "13px", color: "red"}} >please select image </div>}
        </div>
      </div>

      {/* Action Buttons */}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          padding: "10px 30px 15px",
          gap: "20px",
        }}
      >
        <Button
          text={selectedProduct ? "Update" : "Add"}
          isDisabled={
            Object.values(error).some((err) => err) || 
            (!selectedProduct?.id && !productInfo.product_image) ||(!productInfo.name || !productInfo.points)
          }
          onClick={() => {
            if (selectedProduct) {
              handleUpdateProduct(selectedProduct?.id,productInfo);
            } else {
              createProduct();
            }
          }}
          style={{ width: "90px", height: "35px" }}
        />
        <Button
          text="Cancel"
          onClick={onClose}
          isBtnNotActive={true}
          style={{ width: "90px", height: "35px" }}
        />
      </div>
    </Modal>
  );
}
