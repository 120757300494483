import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getOnboardedUsers = async (besId, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/customerSuccessEngineer/stores/onborded/Users", {
            besId: besId ? besId : undefined,
            pageNumber: pageNumber ? pageNumber : undefined,
            pageSize: pageSize ? pageSize : undefined
        })
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 204) {
            return [];
        }
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting onboarded users ");
        }
        return res.users;
    } catch (err) {
        throw (err)
    }
}


export const getRequestsIncentives = async (besId, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/customerSuccessEngineer/stores/requests/incentives", {
            besId: besId ? besId : undefined,
            pageNumber: pageNumber ? pageNumber : undefined,
            pageSize: pageSize ? pageSize : undefined
        })
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 204) {
            return [];
        }
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting request incentives ");
        }
        return res.requests;
    } catch (err) {
        throw (err)
    }
}


export const getAllSettlements = async (besId, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/customerSuccessEngineer/stores/all/settlements", {
            besId: besId ? besId : undefined,
            pageNumber: pageNumber ? pageNumber : undefined,
            pageSize: pageSize ? pageSize : undefined
        })
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 204) {
            return [];
        }
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting request incentives ");
        }
        return res.settlements;
    } catch (err) {
        throw (err)
    }
}