import React, { useEffect, useRef, useState } from 'react'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { getAllUsers } from '../../apis/adminAPIs/users'
import TableView from '../../ReusableComponents/TableView/TableView'
import Search from '../../ReusableComponents/Search'
import _debounce from 'lodash/debounce';
import { generateCSV } from '../../utils/generateAndDownloadCsv'
import { useNavigate } from 'react-router'
import UsersFilter from '../../AdminComponents/Users/SubComponents/UsersFilter'
import { Button } from '../../Components/Helper'
import ListInActive from '../../assets/listInactive.png'
import ListActive from '../../assets/listActive1.png'
import MapActive from '../../assets/mapActive1.png'
import MapInactive from '../../assets/map.png'
import ViewUsersOnLocation from './ViewUsersOnMaps'
import useWindowSize from '../../hooks/useWindowSize'
import InteractiveGraph from '../BhuMeetFinance/Orders/SubComponents/OrdersGraph'
const PAGE_SIZE = 15

const csvHeader = [
    { label: "Name", key: "name" },
    { label: "Phone Number", key: "mobile_number" },
    { label: "Language Preference", key: "language_preference" },
    { label: "Occupation", key: "occupation" },
    { label: "Total_spraying_requests", key: "spraying_requests" },
    { label: "Total_survey_requests", key: "survey_requests" },
]

const OperationsUsers = () => {
    const isFirstRender = useRef(true);
    const [loading, setLoading] = useState()
    const [users, setUsers] = useState([])
    const [userCount, setUserCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedUser, setSelectedUser] = useState()
    const [searchString, setSearchString] = useState("")
    const [usersonMapCount, setUsersonMapCount] = useState(0);
    const [openFilter, setOpenFilter] = useState(false)
    const [view, setView] = useState("tableView")
    const [requestsGraphData,setrequestsGraphData] = useState([])
    const [filter, setFilter] = useState({
        fromDate: null,
        toDate: null
    })
    const navigate = useNavigate();

    const getAllUsersFunc = async () => {
        try {
            setLoading(true)
            const { fromDate, toDate } = filter
            getAllUsers(PAGE_SIZE, currentPage, searchString, fromDate, toDate).then(users => {
                Loading.set(false)
                setLoading(false)
                setUsers(users?.allUsers)
                setUserCount(users.count || 0)
                
                setrequestsGraphData(users.allUsersstats)
            })
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup("Error", err, "ERROR")
        }
    }

    const downloadCSV = async () => {
        Loading.set(true)
        const { fromDate, toDate } = filter
        const users = await getAllUsers(searchString, fromDate, toDate)
        generateCSV(csvHeader, users?.allUsers, "output")
        Loading.set(false)
    }

    useEffect(() => {
        Loading.set(true)
        setLoading(true)
        getAllUsersFunc()
    }, [])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const debouncedGetAllUsers = _debounce(() => {
            getAllUsersFunc();
        }, 2000);

        debouncedGetAllUsers();

        return () => {
            debouncedGetAllUsers.cancel();
        };
    }, [searchString, currentPage, filter.fromDate, filter.toDate]);
    return (

        <MainContentWrapper
            headerStyle={{ minWidth: "150px" }}
            leftContent={`Users (${view === "tableView" ? userCount : usersonMapCount})`}
            rightContent={
                <div style={{ display: "flex", alignItems: "end", gap: "30px", width: "100%", justifyContent: "space-between" }}>
                    <Search
                        searchValue={searchString}
                        onChange={(value) => {
                            setLoading(true);
                            setSearchString(value)
                            setCurrentPage(1)
                        }}
                        height={"45px"}
                        width={"200px"}
                    />

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        width: "50%",
                        justifyContent: "flex-end",
                        gap: "2rem"

                    }}>
                        <UsersFilter
                            filter={filter}
                            open={openFilter}
                            onClose={() => setOpenFilter(false)}
                            onFilterChange={(filter) => {
                                setFilter(filter)
                            }}
                            loading={loading}
                            setOpenFilter={setOpenFilter}
                        />
                        <Button
                            text={"Export CSV"}
                            style={{ width: "120px", height: "38px", fontSize: "15px" }}
                            onClick={() => {
                                downloadCSV()
                            }}
                        />
                        <div style={{ width: "80px", height: "40px", display: "flex", marginLeft: "5px", borderRadius: "5px", border: "1px solid gray", alignItems: "center" }}>
                            <div
                                onClick={() => {
                                    setCurrentPage(1);
                                    setView("tableView")
                                }}
                                style={{ cursor: "pointer", padding: "1px 8px ", overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}
                                className={`${view === "tableView" ? 'list_view_wrapper' : ''}`}
                            >
                                <div className='img'>
                                    <img color='white' width={25} src={view === "tableView" ? ListActive : ListInActive} />
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setCurrentPage(1);
                                    setView("map")
                                }}
                                style={{ cursor: "pointer", padding: "1px 8px ", overflow: "hidden", display: "flex", alignItems: "center", height: "100%" }}
                                className={`${view === 'map' ? 'map_view_wrapper' : ''}`}>
                                <div className='img'>
                                    <img width={25} src={view === 'map' ? MapActive : MapInactive} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        >

<InteractiveGraph
          showZoom
          data={requestsGraphData}
          lineType={0}
            />

            {view === "tableView" ?
                <TableView
                    isLoading={loading}
                    tableContainerHeight={userCount < 15 ? "calc(100% - 40px)" : "calc(100% - 65px)"}
                    showSrNo
                    onStateChange={(changedState) => {
                        setLoading(true);
                        setCurrentPage(changedState.pageNumber)
                    }}
                    onClickRow={(user) => {
                        localStorage.setItem("dsp_id", user.id)
                        setSelectedUser(user)
                        navigate('/userDetails', { state: { user: user } });
                    }}
                    onClickLink={(request) => { }}
                    columns={[
                        { Header: "Name", accessor: "name", width: "10%" },
                        { Header: "Phone number", accessor: "mobile_number", width: "10%", },
                        { Header: "Language Preference", accessor: "language_preference", width: "10%" },
                        { Header: "Occupation", accessor: "occupation", width: "5%", },
                        { Header: "Created date", accessor: "date_created", width: "10%", isDate: true },
                        { Header: "Status", accessor: "user_active_label", showColor: true, width: "10%" },
                    ]}
                    data={users.map((u) => {
                        return (
                            {
                                ...u,
                                user_active_label: u.is_active ? "Active" : "Inactive",
                                color: u.is_active ? 'green' : 'red'
                            }
                        )
                    })}
                    count={userCount}
                    pageNumber={currentPage}
                    pageSize={PAGE_SIZE}
                /> :
                <ViewUsersOnLocation filters={filter} setUsersonMapCount={setUsersonMapCount} searchString={searchString} />
            }


        </MainContentWrapper>
    )
}

export default OperationsUsers