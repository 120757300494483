import React, { useEffect, useRef, useState } from "react";
import "./ViewPilotHistoryOnMap.css";
import roadMapView from "../../../../assets/roadMapView.png";
import sateliteView from "../../../../assets/sateliteView.png";
import Map from "../../../../features/LeafletMap/Map";
import { MAP_URLS } from "../../../../appConstants";
import { getPilotLocationsHistoryDSP } from "../../../../apis/dronePilots";
import Popup from "../../../../ReusableFunctions/Popup";
import MarkerIcon from "../../../../assets/locationDots.svg";
import CurrentLocationMarker from "../../../../assets/currentLocationDot.svg";
import L from "leaflet";
import "react-datepicker/dist/react-datepicker.css";
import calender from "../../../../assets/blueCalendar.svg";
import fullScreenIcon from "../../../../assets/fullScreenIcon.png";
import collapseIcon from "../../../../assets/collapse.png";
import LoaderComponent from "../../../../ReusableComponents/LoaderComponent";

let baseLayer;
export default function ViewPilotHistoryOnMap({ pilotLocations }) {
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationTrail, setLocationTrail] = useState(undefined);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const mapContainerRef = useRef(null);
  const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer);

  const markerIcon = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [20, 20],
  });

  const lastLocationMarker = L.icon({
    iconUrl: CurrentLocationMarker,
    iconSize: [20, 20],
  });


  const toggleFullScreen = () => {
    setIsFullScreen((prev)=>!prev)
    if (!document.fullscreenElement) {
      mapContainerRef.current.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message}`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

  const initMap = (map) => {
    setMap(map);
  };

  useEffect(() => {
    if (map) {
      if (locationTrail) {
        locationTrail.remove();
      }
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          map.removeLayer(layer);
        }
      });

      if (pilotLocations.length > 0) {
        // Create an array of [lat, lng] coordinates
        const coordinates = pilotLocations.map((location) => [
          location.lat,
          location.lng,
        ]);

        coordinates.forEach((coord, index) => {
          const isLast = index === coordinates.length - 1;

          // Create the marker
          const marker = L.marker(coord, {
            icon: L.divIcon({
              className: isLast ? "blinking-marker" : "",
              html: `<img src="${
                isLast ? CurrentLocationMarker : MarkerIcon
              }" style="width:20px; height:20px;" />`,
            }),
          });

          // Bind tooltip to the marker
          const locationTime = pilotLocations[index].time;
          const formattedTime = new Date(locationTime).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          marker
            .bindTooltip(
              `<div style="font-size: 12px; font-weight: bold; color: #333;">
                ${formattedTime}
              </div>`,
              { permanent: false, direction: "top", offset: [0, -10] }
            )
            .addTo(map);
        });

        // Create and add the polyline
        const trail = L.polyline(coordinates, {
          color: "#0F5EFF",
          weight: 4,
          opacity: 0.8,
          dashArray: "5, 10",
          smoothFactor: 1,
        }).addTo(map);

        map.fitBounds(trail.getBounds(), { padding: [40, 40] });

        setLocationTrail(trail);
      } else {
        map.setView([19.993982, 73.790416], 10);
      }
    }
  }, [map, pilotLocations]);

  const switchBaseLayer = () => {
    const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite";
    setActiveBaseLayer(activeLayer);
    if (baseLayer) {
      baseLayer.setUrl(
        activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP
      );
    }
  };
  return (
    <div className="pilots_map_view_wrapper">
      <div className="heading">Location History</div>
      <div
        style={{
          height: "350px",
          width: "100%",
          marginBottom: "10px",
          padding: "5px",
          backgroundColor: "transparent",
        }}
        className="map_view_wrapper"
      >
        <div
          ref={mapContainerRef}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 50,
            border: "1px solid rgba(191, 200, 217, 1)",
            borderRadius: "5px",
          }}
        >
          <Map
            setBaseLayerToState={(bL) => {
              baseLayer = bL;
            }}
            initZoom={6}
            handleMapClick={() => {}}
            maxZoom={23}
            style={{
              height: "100%",
              borderRadius: "5px",
            }}
            mapInitialized={(map) => {
              initMap(map);
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: -10,
              left: "15px",
              height: "50px",
              zIndex: "999",
              cursor: "pointer",
            }}
          >
            <img
              src={isFullScreen ? collapseIcon : fullScreenIcon}
              onClick={toggleFullScreen}
              alt=""
              width={isFullScreen ? "25px" : "20px"}
              height={"25px"}
            />
          </div>
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm z-[1000]">
              <LoaderComponent width={"4%"} height={"4%"} />
            </div>
          )}
          <div
            style={{
              border: "solid 1px rgb(102, 102, 102, 0.3)",
              height: "25px",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: "999",
              position: "absolute",
              right: "10px",
              bottom: "24px",
            }}
            onClick={switchBaseLayer}
          >
            <img
              style={{ width: "80px", height: "25px", borderRadius: "4px" }}
              src={activeBaseLayer === "satelite" ? roadMapView : sateliteView}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
