import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getAllStores = (fromDate, toDate, pageSize, pageNumber,endpoint) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + endpoint, {
                fromDate: fromDate ? fromDate : undefined,
                toDate: toDate ? toDate : undefined,
                pageNumber: pageNumber ? pageNumber : undefined,
                pageSize: pageSize ? pageSize : undefined
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data?.allStores);
            } else {
                reject(
                    data?.message || "Something went wrong while getting stores."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting stores.");
        }
    });
};


export const getStoresCount = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/admin/stores/store/count`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting stores count.");
        }
        return res.stores_count;
    } catch (err) {
        throw (err)
    }
}

export const getOnboardedUsers = async (besId, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/admin/stores/onborded/Users", {
            besId: besId ? besId : undefined,
            pageNumber: pageNumber ? pageNumber : undefined,
            pageSize: pageSize ? pageSize : undefined
        })
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 204) {
            return [];
        }
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting onboarded users ");
        }
        return res.users;
    } catch (err) {
        throw (err)
    }
}


export const getRequestsIncentives = async (besId, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/admin/stores/requests/incentives", {
            besId: besId ? besId : undefined,
            pageNumber: pageNumber ? pageNumber : undefined,
            pageSize: pageSize ? pageSize : undefined
        })
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 204) {
            return [];
        }
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting request incentives ");
        }
        return res.requests;
    } catch (err) {
        throw (err)
    }
}


export const getAllSettlements = async (besId, pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/admin/stores/all/settlements", {
            besId: besId ? besId : undefined,
            pageNumber: pageNumber ? pageNumber : undefined,
            pageSize: pageSize ? pageSize : undefined
        })
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status === 204) {
            return [];
        }
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting request incentives ");
        }
        return res.settlements;
    } catch (err) {
        throw (err)
    }
}


export const updateStore = (store) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/admin/stores/update/status",
                {
                    method: "PUT",
                    body: JSON.stringify(store),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.updatedstore);
            } else {
                reject(
                    data?.message || "Something went wrong while updating store"
                );
            }
        } catch (error) {
            reject("Something went wrong while updating store");
        }
    });
}