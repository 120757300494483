import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { MAP_URLS } from "../appConstants";
import collapseIcon from '../assets/collapse.png';
import fullScreenIcon from '../assets/fullScreenIcon.png';
import roadMapView from '../assets/roadMapView.png';
import sateliteView from '../assets/sateliteView.png';
import MarkerIcon from "../assets/marker.png";

const LocationOnMap = ({ lat, lon }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const tileLayerRef = useRef(null);

    const [activeBaseLayer, setActiveBaseLayer] = useState("satelite");
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            mapContainerRef.current.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
            setIsFullScreen(true);
        } else {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    const switchBaseLayer = () => {
        const nextLayer = activeBaseLayer === "road-map" ? "satelite" : "road-map";
        setActiveBaseLayer(nextLayer);

        if (tileLayerRef.current) {
            tileLayerRef.current.setUrl(nextLayer === "road-map" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP);
        }

        const markerCoordinates = [lat, lon]; // Replace with your marker's coordinates
        if (mapRef.current) {
            mapRef.current.setView(markerCoordinates, 15); // Adjust zoom level if needed
        }
    };

    useEffect(() => {
        const initialCoordinates = (lat && lon) ? [lat, lon] : [19.993982, 73.790416];
        const zoomLevel = 13;

        if (!mapRef.current) {
            mapRef.current = L.map('mapId').setView(initialCoordinates, zoomLevel);

            tileLayerRef.current = L.tileLayer(MAP_URLS.ROAD_MAP, {
                attribution: false,
            }).addTo(mapRef.current);

            if (lat && lon) {
                const customIcon = L.icon({
                    iconUrl: MarkerIcon,
                    iconSize: [20, 26],
                    iconAnchor: [20, 40],
                    popupAnchor: [0, -40],
                });

                L.marker(initialCoordinates, { icon: customIcon }).addTo(mapRef.current);
            }
        }
    }, []);

    return (
        <div
            ref={mapContainerRef}
            style={{
                position: "relative",
                zIndex: 1000,
                right: 10,
                bottom: 0,
                width: "100%",
                height: "100%",
                borderRadius: "8px",
            }}
        >
            <div id="mapId"
                style={{
                    height: '100%', width: '100%', borderRadius: "8px",
                    opacity: (lat && lon) ? '1' : '0.5'
                }} />

            {(lat && lon) ?
                <>
                    <div
                        style={{
                            border: 'solid 1px rgb(102, 102, 102, 0.3)',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            zIndex: '999',
                            position: "absolute",
                            right: "10px",
                            bottom: '10px',
                            height: "25px",
                            width: "60px"
                        }}
                        onClick={switchBaseLayer}
                    >
                        <img
                            style={{ width: '100%', height: '100%', borderRadius: '4px' }}
                            src={activeBaseLayer === "road-map" ? roadMapView : sateliteView}
                            alt="Switch Layer"
                        />
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            bottom: '-20px',
                            left: "10px",
                            height: '50px',
                            zIndex: '999',
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src={isFullScreen ? collapseIcon : fullScreenIcon}
                            onClick={toggleFullScreen}
                            alt="Toggle Fullscreen"
                            width={isFullScreen ? "25px" : "20px"}
                            height={"25px"}
                        />
                    </div></> :
                <div style={{
                    height: "100%",
                    fontSize: "25px",
                    fontWeight: "600",
                    width: "100%", position: "absolute", top: "0px", left: "0px", display: "flex", justifyContent: "center", alignItems: "center"
                }}>
                    location not found !
                </div>
            }
        </div>
    );
};

export default LocationOnMap;
