import React, { useEffect, useState } from 'react'
import Modal from '../../../../ReusableComponents/Modal'
import { AEROMEGH_SERVICES, GLOBAL_STYLES, STATUS } from '../../../../appConstants'
import { getLocations } from '../../../../apis/locations'
import Popup from '../../../../ReusableFunctions/Popup'
import LoaderComponent from '../../../../ReusableComponents/LoaderComponent'
import { Button } from '../../../../Components/Helper'
import CloseIcon from "../../../../assets/closebtn.svg"
import Filter from "../../../../assets/filter.svg"
import Tippy from '@tippyjs/react'
import { reduceLongName } from '../../../../ReusableFunctions/reusableFunctions'
import SingleFilter from './SingleFilter'
import CustomTooltip from '../../../../ReusableComponents/CustomTooltip'
import calender from "../../../../assets/blueCalendar.svg"
import clock from "../../../../assets/blueClock.svg"
import DatePicker from "react-datepicker";
import DateTimePicker from '../../../../ReusableComponents/DateTimePicker'
const FilterRequest = ({ user, open, onClose, callback, setOpenFilter, dateTime, screenWidth, loading, setLoading, isFromUserDetails }) => {
    const [filters, setFilter] = useState({ services: [], statuses: [], locations: [] })
    const [locations, setLocations] = useState(()=>{
        const savedLocations = JSON.parse(localStorage.getItem('allLocations'));
        if (savedLocations) {
            return savedLocations;
        }
        return []
    })
    const [appliedFilters, setappliedFilters] = useState([]);
    const [chipInputSize, setChipInputSize] = useState(0);
    const [fromDateToDate, setFromDateToDate] = useState({
        fromDate: dateTime ? dateTime.fromDate : null,
        toDate: dateTime ? dateTime.toDate : null
    })


    const [savedFilters, setSavedFilters] = useState(null);
    useEffect(() => {
        const loadSavedFilters = () => {
            const savedFiltersString = localStorage.getItem('requestFilters');
            if (savedFiltersString) {
            const parsedFilters = JSON.parse(savedFiltersString);
            setSavedFilters(parsedFilters);
            setFilter(parsedFilters);
            setappliedFilters(createAppliedFiltersArray(parsedFilters));
        }
    };
    loadSavedFilters();
      }, []);
    
    
      const createAppliedFiltersArray = (filters) => {
        const selectedStatus = filters.statuses.map((st) => ({
          name: st,
          type: "status"
        }));
        console.log("filters and locations ",filters.locations ,locations);
        const selectedLocations = filters.locations.map(locationId => {
          const location = locations.find(loc => loc.id === locationId);
          return { ...location, type: "location" };
        });
        console.log("selected locations",selectedLocations);
        return [ ...selectedStatus, ...selectedLocations];

      };
    

    const handleOnChangeServices = (e) => {
        const value = e.target.value
        let newServices = filters.services
        if (e.target.checked) {
            newServices = [...filters.services, value]
        } else {
            newServices = newServices.filter(service => service !== value)
        }
        newServices = Array.from(new Set(newServices))
        setFilter(prev => ({ ...prev, services: newServices }))
    }

    const handleOnChangeStatus = (e) => {
        const value = e.target.value
        let newStatuses = filters.statuses
        if (e.target.checked) {
            newStatuses = [...filters.statuses, value]
        } else {
            newStatuses = newStatuses.filter(status => status !== value)
        }
        newStatuses = Array.from(new Set(newStatuses))
        setFilter(prev => ({ ...prev, statuses: newStatuses }))
    }

    const handleOnChangeLocation = (e) => {
        const value = e.target.value
        let newLocations = filters.locations
        if (e.target.checked) {
            newLocations = [...filters.locations, value]
        } else {
            newLocations = newLocations.filter(location => location !== value)
        }
        newLocations = Array.from(new Set(newLocations))
        setFilter(prev => ({ ...prev, locations: newLocations }))
    }

    const getLocationFunc = async () => {
        try {
            const locations = await getLocations()
            setLocations(locations)
            localStorage.setItem("allLocations", JSON.stringify(locations));
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    const updateFilter = (key, filter) => {
        let newValues = filters[key].filter(value => value !== (key === 'locations' ? filter.id : filter.name));
        newValues = Array.from(new Set(newValues));
        setFilter(prev => ({ ...prev, [key]: newValues }));
        setappliedFilters(prev => prev.filter(f => {
            if (f.type === 'location') {
                return f.id !== filter.id;
            } else {
                return f.name !== filter.name;
            }
        }));
        callback({ ...filters, [key]: newValues },);
    };

    const clearFilter = async (filter) => {
        switch (filter.type) {
            case 'service':
                updateFilter('services', filter);
                break;
            case 'status':
                updateFilter('statuses', filter);
                break;
            case 'location':
                updateFilter('locations', filter);
                break;
            default:
                break;
        }
    };


    useEffect(() => {
        setChipInputSize(getChipInputSize(screenWidth))
    }, [screenWidth])

    const getChipInputSize = (screenSize) => {
        if (isFromUserDetails) {
            switch (screenSize) {
                case 'x-large':
                    return 6;
                case 'large':
                    return 5;
                case 'medium':
                    return 3;
                case 'small':
                    return 2;
                default:
                    return 6;
            }
        } else {
            switch (screenSize) {
                case 'x-large':
                    return 6;
                case 'large':
                    return 4;
                case 'medium':
                    return 3;
                case 'small':
                    return 2;
                default:
                    return 6;
            }
        }
    }


    useEffect(() => {
        getLocationFunc()
    }, [])

    return (
        <>
            <Modal header={"Filter"} contentStyle={{ width: "730px", height: "450px" }} headingStyle={{ fontSize: "18px", fontWeight: 600, }} headerContainerStyle={{ padding: "20px" }} open={open} onClose={onClose}>
                <div style={{ width: "100%", borderTop: "0.5px solid lightgray" }}></div>
                {loading ?
                    <LoaderComponent /> :
                    <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR, height: "calc(100% - 120px)", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ padding: "20px 30px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                            <DateTimePicker
                                title="From"
                                minTime={new Date().setHours(0, 0, 0, 0)}
                                maxTime={new Date().setHours(23, 59)}
                                hideNxt={new Date()}
                                selectedDateTime={fromDateToDate.fromDate}
                                onChange={(date) => {
                                    setFromDateToDate({
                                        ...fromDateToDate,
                                        fromDate: date
                                    })
                                }}
                            />
                            <DateTimePicker
                                title="To"
                                hidePrev={fromDateToDate.fromDate}
                                selectedDateTime={fromDateToDate.toDate}
                                minTime={new Date(fromDateToDate?.fromDate).toDateString() === new Date(fromDateToDate?.toDate).toDateString()
                                    ? new Date(new Date(fromDateToDate.fromDate).setMinutes(new Date(fromDateToDate.fromDate).getMinutes() + 1))
                                    : new Date().setHours(0, 0, 0, 0)}
                                maxTime={new Date().setHours(23, 59)}
                                onChange={(date) => {
                                    if (new Date(fromDateToDate.fromDate) < new Date(date)) {
                                        const endOfDay = new Date(date);
                                        endOfDay.setHours(23, 59, 59, 999);
                                        setFromDateToDate({
                                            ...fromDateToDate,
                                            toDate: endOfDay
                                        })
                                    } else {
                                        setFromDateToDate({
                                            ...fromDateToDate,
                                            toDate: fromDateToDate.toDate ? new Date(fromDateToDate.toDate) : new Date()
                                        })
                                    }

                                }}
                                isDisable={!(fromDateToDate.fromDate)}
                            />
                        </div>
                        <div style={{ padding: "20px 30px", display: "grid", gridTemplateColumns: "1fr 1fr ", height: "65%", rowGap: "10px", fontSize: '14px' }}>
                    
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Status</div>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Locations</div>
                            <div style={{ fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "23px", color: "#3B3B3B", fontWeight: 500, marginTop: "5px", maxHeight: "240px", marginRight: "23px", overflowY: "auto" }}>
                                {Object.keys(STATUS).map((key) => {
                                    return <SingleFilter
                                        isChecked={filters.statuses.filter(status => status == STATUS[key]).length > 0}
                                        onChange={handleOnChangeStatus}
                                        value={STATUS[key]}
                                        title={STATUS[key]}
                                    />
                                })}
                            </div>
                            <div style={{ fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "23px", overflowY: "auto", height: "100%", color: "#3B3B3B", fontWeight: 500, marginTop: "5px", maxHeight: "240px", marginRight: "20px" }}>
                                {locations.map(location => <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <div style={{ width: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <input
                                            checked={filters.locations.filter(loc => loc === location.id).length > 0}
                                            onChange={handleOnChangeLocation}
                                            value={location.id}
                                            type={"checkbox"}
                                            className="input-checkbox"
                                        />
                                    </div>
                                    <Tippy content={location.address}>
                                        <span style={{ lineHeight: 1.1, fontSize: "15px", fontWeight: 500 }}>{reduceLongName(location.name, 20)}</span>
                                    </Tippy>
                                </div>)}
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 30px", borderTop: '0.5px solid #BFC8D9' }}>
                            <div onClick={async () => {
                                setFilter({ services: [], statuses: [], locations: [] })
                                setFromDateToDate({ fromDate: null, toDate: null })
                            }} style={{ width: "100px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                                Clear all
                            </div>
                            <Button
                                text={"Apply"}
                                style={{ width: "130px", height: "38px", fontSize: "15px", marginRight: "10px" }}
                                onClick={async () => {

                                    if (fromDateToDate.fromDate && fromDateToDate.toDate) callback(filters, fromDateToDate)
                                    else callback(filters)

                                    onClose()
                                    let selectedServices = filters.services.map((ser) => ({
                                        name: ser,
                                        type: "service"
                                    }))
                                    let selectedStatus = filters.statuses.map((st) => ({
                                        name: st,
                                        type: "status"
                                    }))
                                    let selectedLocations = filters?.locations?.map(locationId => {
                                        return { ...locations.find(location => location.id === locationId), type: "location" };
                                    });
                                    setappliedFilters((prev) => [...selectedServices, ...selectedStatus, ...selectedLocations]);

                                }}
                            />
                        </div>
                    </div>
                }
            </Modal>
            <div style={{ display: "flex " }}>
                <div className='flex justify-end gap-2 mr-1 '>
                    <div className='flex gap-2  items-center'>
                        {
                            appliedFilters?.length > 0 && appliedFilters?.slice(0, chipInputSize).map((filter) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            fontSize: "12px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",
                                            background: "#0F5EFF1A",
                                            border: "1px solid #0F5EFF",
                                            height: "30px",
                                            width: "100%",
                                            borderRadius: "5px",
                                            color: "#0F5EFF",
                                            padding: "0 15px"
                                        }}
                                    >
                                        <Tippy allowHTML={true} content={<CustomTooltip content={filter.name === STATUS.HOLD ? filter.name?.split(" ")[1] : filter.name?.split(" ")[0]} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                            <span > {filter.name === STATUS.HOLD ? filter.name.split(" ")[1] : filter.name?.split(" ")[0].length > 7 ? reduceLongName(filter.name?.split(" ")[0], 7) : filter.name?.split(" ")[0]}</span>
                                        </Tippy>
                                        <img className='cursor-pointer' onClick={() => clearFilter(filter)} src={CloseIcon} alt='closebtn' />
                                    </div>
                                )
                            })
                        }
                        {
                            appliedFilters.length && appliedFilters.length > chipInputSize ? (
                                <div style={{ fontSize: "13px", color: "#0F5EFF", minWidth: "fit-content" }}>+{Math.abs(appliedFilters.length - chipInputSize)} more </div>
                            ) : null
                        }
                    </div>
                </div>
                {
                    <Button
                        innerClassName={"filter-btn-inner"}
                        iconStyle={{ width: "18px", marginRight: "10px" }}
                        text={"Filter"}
                        icon={Filter}
                        className={"filter-btn"}
                        onClick={() => {
                            setOpenFilter(true)
                        }}
                        textClassName={"user-details-filter-text"}
                    />
                }
            </div>
        </>
    )
}

export default FilterRequest