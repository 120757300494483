import React from 'react'
import AdminStatusCountCard from '../SubComponents/AdminStatusCountCard'
import { PDRL_ADMIN_ROUTES } from '../../../../routes';
import serviceCardIcon from "../../../../assets/serviceCardIcon.svg"
const StatusCountAndAmountContainer = ({ requestsStatuses, callback, statusFilters }) => {
     return (
          <div className='orders_stats_cards_wrapper'>
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Total Orders") ?? { order_status: "Total Orders", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Rescheduled")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Rescheduled") ?? { order_status: "Rescheduled", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("InProgress")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "InProgress") ?? { order_status: "InProgress", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Out for Service")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Out for Service") ?? { order_status: "Out for Service", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Paid")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Paid") ?? { order_status: "Paid", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Placed")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Placed") ?? { order_status: "Placed", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Cancelled")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Cancelled") ?? { order_status: "Cancelled", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon} currentWidth
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("On Hold")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "On Hold") ?? { order_status: "On Hold", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Accepted")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Accepted") ?? { order_status: "Accepted", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
               <AdminStatusCountCard
                    loading={false}
                    isActive={statusFilters.includes("Completed")}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Completed") ?? { order_status: "Completed", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
                    callback={callback}
               />
          </div>
     )
}

export default StatusCountAndAmountContainer